import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Reference.module.css';
import {
    referenceSelector
} from './referenceSlice';
import ReferenceReference from './ReferenceReference';
import { referenceListStateEnum } from './enum/referenceListStateEnum';
import { loadReferenceList } from './thunks/loadReferenceList';
import LocalActivityIndicator from '../common/LocalActivityIndicator';
import { useTranslation } from 'react-i18next';


const ReferenceReferenceList = () => {

    const reference = useSelector(referenceSelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(
        () => {
            if (reference.referenceListState === referenceListStateEnum.EMPTY)
                dispatch(loadReferenceList());
        }, 
        [dispatch, reference]
    );

    return (
        <div className={styles.referenceReferenceList}>
            { reference.referenceListState === referenceListStateEnum.UNSUCCESSFULLY_LOADED &&
                <div className={[styles.messageBox, styles.negative].join(' ')}>
                    <p>{t('Nepodařilo se načíst data, zkuste to prosím ještě jednou.')}</p>
                    <button className={styles.button} onClick={() => dispatch(loadReferenceList())}>{t('Načíst znovu')}</button>
                </div>
            }
            { reference.referenceListState === referenceListStateEnum.PENDING &&
                <div className={styles.activityIndicatorContainer}><LocalActivityIndicator size="small" /></div>
            }
            { 
                reference.referenceListState === referenceListStateEnum.READY &&
                reference.referenceList.length > 0 &&
                <ul className={styles.list}>{
                    reference.referenceList.map((item) => (<ReferenceReference key={item.id} reference={item} />))
                }</ul>
            }
            { 
                reference.referenceListState === referenceListStateEnum.READY &&
                reference.referenceList.length === 0 &&
                <p>{t('Nemáte ještě žádné reference')}</p>
            }
        </div>
    );
};


export default ReferenceReferenceList;