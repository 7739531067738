import React from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { programKindEnum } from '../user/enum/programKindEnum';
import { userSelector } from '../user/userSlice';
import { harmonogramSelector } from '../harmonogram/harmonogramSlice';
import styles from './Index.module.css';
import { stateStageEnum } from "../harmonogram/enum/stateStageEnum";
import { LocalDate } from "../../lib/localDate";

const IndexHurdle = () => {

    const { t } = useTranslation();
    const harmonogram = useSelector(harmonogramSelector);

    const hurdleList = harmonogram.harmonogram.filter(
        state => ( 
            [stateStageEnum.CURRENT, stateStageEnum.FUTURE].includes(state.stage) &&
            state.runningEvent
        )
    ).flatMap(
        state => state.eventList.filter(
            event => (new LocalDate(event.to)).compare((new LocalDate()).today()) == 1
        )
    );

    return (
        <>
            { hurdleList.length > 0 && 
                hurdleList.map(
                    (hurdle, index) => 
                        <div key={index} className={[styles.card, styles.hurdle].join(' ')}>
                            <div className={styles.header}>
                                <span className={styles.title}>{t("Mimořádná událost")}</span>
                            </div>
                            <div className={styles.content} dangerouslySetInnerHTML={{ __html: hurdle.text }} />
                            <div className={styles.footer}>
                                <Link className={styles.button} to={'/hurdle/' + hurdle.id}>
                                    <span>{t("Zobrazit více")}</span>
                                    <i></i>
                                </Link>
                            </div>
                        </div>
                )
            }
        </>

        // <div className={[styles.card, styles.hurdle].join(' ')}>
        //     <div className={styles.header}>
        //         <span className={styles.title}>{t("Mimořádná událost")}</span>
        //     </div>
        //     <div className={styles.content}>
        //         <h2>Vaše platba mešká !</h2> 
        //         <p>Vaše splatná záloha mešká! Toto prodlení bohužel bude mít vliv na celkový termín předání Vašeho domu.</p> 
        //     </div>
        //     <div className={styles.footer}>
        //         <button type="button" className={styles.button}>
        //             <span>{t("Zobrazit více")}</span>
        //             <i></i>
        //         </button>
        //     </div>
        // </div>
    );
};

export default IndexHurdle;