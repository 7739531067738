import React from 'react';
import Communication from './Communication';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../common/BreadCrumbs';
import Page from '../common/Page';

const CommunicationPage = () => {

    const { t } = useTranslation();

    return (
        <Page>
            <BreadCrumbs stack={[
                {title: t("Domů"), path: "/"},
                {title: t("Komunikace"), path: "/communication"}
            ]} />
            <Communication />
        </Page>
    );
};

export default CommunicationPage;