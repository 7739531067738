import axios from "axios";
import i18next from "i18next";


export const getMemberList = (apiKey) => axios({
        url: 'https://api.es-europe.eu/member',
        method: 'GET',
        headers: {
            token: apiKey
        }
    })
    .then(response => response.data.data)
    .then(team => team.memberList.map(
        (member) => ({
            id:         Number(member.id),
            name:       String(member.name),
            position:   Number(member.position),
            imageUrl:   String(member.imageUrl)
        })
    ))
    .catch(error => Promise.reject(i18next.t('Nepodařilo se načíst seznam členů týmu')));