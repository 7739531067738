import { createAsyncThunk } from '@reduxjs/toolkit';
import { postPasswordRequest } from '../../../api/postPassword';
import { setRequestedPassword } from '../userSlice';


export const loadPassword = createAsyncThunk(
    'user/loadPassword',
    
    async (invitationKey, { dispatch }) => {
        return postPasswordRequest(invitationKey)
            .then(password => dispatch(setRequestedPassword(password)));
    }
);
