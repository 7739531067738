import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Reference.module.css';
import ReferenceReferenceList from './ReferenceReferenceList';
import ReferenceNewReference from './ReferenceNewReference';


const Reference = () => {

    const { t } = useTranslation();
    

    return (
        <div className={styles.reference}>
            <div className={styles.motivation}>
                <h3>{t("Zaplatíme Vám splátky hypotéky")}</h3>
                {t("REFERENCE_MOTIVATION").split("\n").map((line, index) => <p key={index}>{line}</p>)}
            </div>
            <div className={styles.layout}>
                <ReferenceNewReference />
                <ReferenceReferenceList />
            </div>
        </div>
    );
}

export default Reference;