import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styles from './User.module.css';
import {
    setMessage,
    userSelector
} from './userSlice';
import LocalActivityIndicator from '../common/LocalActivityIndicator';
import { login } from './thunks/login';
import { emotionEnum } from './enum/emotionEnum';


const Login = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const user = useSelector(userSelector);

    const validate = () => {
        // return Math.random() < 0.5;

        return true;
    };

    const submit = () => {
        
        if (!validate()) {
            dispatch(
                setMessage({
                    text: t("Vstupní data nejsou validní"),
                    emotion: emotionEnum.NEGATIVE
                }));
            return false;
        }

        dispatch(login({
            user:userName,
            password: password
        }));

        return false;
    };

    return (
        <div className={styles.login}>
            { user.message &&
                <div className={[styles.messageBox, user.message.emotion === emotionEnum.NEGATIVE ? styles.negative : styles.pozitive].join(' ')}>
                    <p>{user.message.text}</p>
                </div>
            }
            <div className={styles.loginForm}>
                <label>{t('uživatelské jméno')}
                    <input type="text" value={userName} onChange={(event) => setUserName(event.target.value)} required minLength="3" autoComplete="username" name="username" />
                </label>
                <label>{t('heslo')}
                    <input type="password" value={password} onChange={(event) => setPassword(event.target.value)} required minLength="3" autoComplete="current-password" name="password" />
                </label>
                { user.pending ?
                    <div className={styles.activityIndicatorContainer}><LocalActivityIndicator size="small" /></div> :
                    <button className={styles.button} onClick={() => submit()}>{t('přihlásit se')}</button>
                }
            </div>
        </div>
    );
};

export default Login;