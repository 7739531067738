import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LocalDate } from '../../lib/localDate';
import { stateStageEnum } from './enum/stateStageEnum';
import styles from './Harmonogram.module.css';
import {
    harmonogramSelector
} from './harmonogramSlice';
import HarmonogramState from './HarmonogramState';

const HarmonogramStateList = () => {

    const { t } = useTranslation();
    const harmonogram = useSelector(harmonogramSelector);

    const formatDate = (date) => new Intl.DateTimeFormat('cs-CZ', {year: 'numeric', month: 'numeric', day: 'numeric'}).format(new Date(date)).replace(/ /g, "");

    const getEndDate = (stateList) => {
        let lastState = null;
        for (let i = stateList.length - 1; i >= 0; i--)
            if ([stateStageEnum.CURRENT, stateStageEnum.FUTURE].includes(stateList[i].stage)) {
                lastState = stateList[i];
                break;
            }
        let endDate = (new LocalDate(lastState.date)).addInterval({day: lastState.expectedLength});
        let today = (new LocalDate()).today();
        if (endDate.compare(today) > 0)
            return endDate.getDate();
        return today.getDate();
    };
    const endDate = getEndDate(harmonogram.harmonogram);

    return (
        // <ul className={styles.harmonogramStateList}>
        //     {harmonogram.harmonogram.map((state) => (<HarmonogramState key={state.id} state={state} />))}

        //     <li className={styles.summary}>
        //         <span className={styles.date}><strong>{formatDate(endDate)}</strong></span>
        //         <span className={styles.content}>
        //             <span className={styles.name}>{t("Dokončení programu")}</span>
        //         </span></li>
        // </ul>

        <ul className={styles.harmonogramStateList}>
            {harmonogram.harmonogram.map((state) => (<HarmonogramState key={state.id} state={state} />))}

            <li className={styles.summary}>
                <span className={styles.date}>{formatDate(endDate)}</span>
                <span className={styles.name}>{t("Dokončení programu")}</span>
            </li>
        </ul>
    );
};

export default HarmonogramStateList;