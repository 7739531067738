import React from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import styles from './Index.module.css';
import { fileSelector } from "../file/fileSlice";
import FileFile from "../file/FileFile";

const IndexFile = () => {

    const { t } = useTranslation();
    const file = useSelector(fileSelector);

    return (
        <div className={[styles.card, styles.file].join(' ')}>
            <div className={styles.header}>
                <span className={styles.title}>{t("Dokumenty")}</span>
            </div>
            <div className={styles.content}>
                <ul>
                    {
                        file.fileList.slice(0,2).map((item) => (
                            <FileFile item={item} key={item.id} />
                        ))
                    }
                    {/* <li className={styles.pdf}>
                        <i></i>
                        <span className={styles.name}>Smlouva o dílo</span>
                        <span className={styles.type}>Pdf dokument</span>
                        <span className={styles.size}>[315kB]</span>
                    </li>
                    <li className={styles.pdf}>
                        <i></i>
                        <span className={styles.name}>Smlouva o smlouvě o dílu</span>
                        <span className={styles.type}>Pdf dokument</span>
                        <span className={styles.size}>[1315kB]</span>
                    </li> */}
                </ul>
            </div>
            <div className={styles.footer}>
                <Link className={styles.button} to={'/file'}>
                    <span>{t("Zobrazit dokumenty")}</span>
                    <i></i>
                </Link>
            </div>
        </div>
    );
};

export default IndexFile;