import React from 'react';
import styles from './Common.module.css';


const ActivityIndicator = () => {

    return (
        <div className={styles.activityIndicator}>Hello</div>
    );
};

export default ActivityIndicator;