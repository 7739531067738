import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Index.module.css';

import IndexThermometer from './IndexThermometer';
import IndexFaze from './IndexFaze';
import IndexHurdle from './IndexHurdle';
import IndexHarmonogram from './IndexHarmonogram';
import IndexDiary from './IndexDiary';
import IndexCommunication from './IndexCommunication';
import IndexFile from './IndexFile';

import LocalActivityIndicator from '../common/LocalActivityIndicator';

// faze
import { loadStateList } from '../faze/thunks/loadStateList';

import { loadAll } from './thunks/loadAll';
import { indexSelector } from './indexSlice';
import { indexStateEnum } from './enum/indexStateEnum';


const Index = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const index = useSelector(indexSelector);

    useEffect(
        () => {
            if (index.indexState === indexStateEnum.EMPTY)
                dispatch(loadAll());
        }, 
        [
            dispatch, 
            index
        ]
    );

    return (
        // <div className={styles.index}>
        //     <h2>{t("Vítejte ve svém klientském prostředí")}</h2>
        //     <div className={styles.reference}>
        //         <Link to="/reference">
        //             <h3>{t("odměna za reference")}</h3>
        //             <span className={styles.description}>{t("Byli jste s námi spokojeni? Doporučte nás svým známým a my vás odměníme například odpuštěním jedné splátky za váš dům!")}</span>
        //         </Link>
        //     </div>
        //     <ul>
        //         { [programKindEnum.PROJECT, programKindEnum.CONSTRUCTION].includes(user.user.programKind) && 
        //             [
        //                 {path: "/reference", name: t("odměna za reference"), className: styles.reference},
        //                 {path: "/harmonogram", name: t("časový harmonogram"), className: styles.harmonogram},
        //                 {path: "/communication", name: t("zprávy"), className: styles.message},
        //                 {path: "/file", name: t("soubory"), className: styles.file},
        //                 {path: "/loan", name: t("půjčky"), className: styles.loan},
        //                 {path: "/helpline", name: t("helpline řediteli"), className: styles.helpline}
        //                 // {path: "/profile", name: t("profil"), className: styles.profile}
        //             ].map(item => <IndexItem item={item} key={item.path} />)
        //         }

        //         { [programKindEnum.WARRANTY_PERIOD].includes(user.user.programKind) && 
        //             [
        //                 {path: "/reference", name: t("odměna za reference"), className: styles.reference},
        //                 {path: "/loan", name: t("půjčky"), className: styles.loan},
        //                 // {path: "/profile", name: t("profil"), className: styles.profile},
        //                 {path: "/service", name: t("servis"), className: styles.service}
        //             ].map(item => <IndexItem item={item} key={item.path} />)
        //         }
        //     </ul>
        // </div>

        <div className={styles.index}>
            { 
                (
                    index.indexState === indexStateEnum.UNSUCCESSFULLY_LOADED
                ) &&
                <div className={[styles.messageBox, styles.negative].join(' ')}>
                    <p>{t('Nepodařilo se načíst data, zkuste to prosím ještě jednou.')}</p>
                    <button className={styles.button} onClick={() => dispatch(loadStateList())}>{t('Načíst znovu')}</button>
                </div>
            }
            { 
                (
                    index.indexState === indexStateEnum.PENDING
                ) &&
                <div className={styles.activityIndicatorContainer}><LocalActivityIndicator size="small" /></div>
            }
            {
                (
                    index.indexState === indexStateEnum.READY
                ) &&
                <>
                    <IndexThermometer />
                    <IndexFaze />
                    <IndexHurdle />
                    <IndexHarmonogram />
                    {/* <IndexDiary /> */}
                    <IndexCommunication />
                    <IndexFile />
                </>
            }
        </div>
    );
};

export default Index;