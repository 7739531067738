import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFile } from '../../../api/getFile';
import { downloadFile } from '../../../lib/downloadFile';
import { setDownloadProgress, setDownloadId } from '../fileSlice';


export const loadFile = createAsyncThunk(
    'file/loadFile',
    async (file, { dispatch, getState }) => {
        const state = getState();
        
        dispatch(setDownloadId(file.id));

        return getFile(
            state.user.key, 
            file.id, 
            percentCompleted => dispatch(setDownloadProgress(percentCompleted))
        ).then(data => {
            downloadFile(file.name, data);
            return true;
        });
    }
);
