import { createSlice } from '@reduxjs/toolkit';
import { loanListStateEnum } from './enum/loanListStateEnum';
import { loadLoanList } from './thunks/loadLoanList';


export const loanSlice = createSlice({
    name: 'loan',
    initialState : {
        loanListState: loanListStateEnum.EMPTY,
        loanList: null
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadLoanList.fulfilled, (state, action) => {
                state.loanList = action.payload;
                state.loanListState = loanListStateEnum.READY;
            })
            .addCase(loadLoanList.pending, (state, action) => {
                state.loanListState = loanListStateEnum.PENDING;
            })
            .addCase(loadLoanList.rejected, (state, action) => {
                state.loanListState = loanListStateEnum.UNSUCCESSFULLY_LOADED;
            });
    }
});

// export const { 
    
// } = loanSlice.actions;

export const loanSelector = (state) => state.loan;

export default loanSlice.reducer;