import { createAsyncThunk } from '@reduxjs/toolkit';
import { postReference } from '../../../api/postReference';


export const sendReference = createAsyncThunk(
    'reference/sendReference',
    async ({ firstName, lastName, phone, mail }, { getState }) => {
        const state = getState();

        return postReference(
            state.user.key, 
            firstName, 
            lastName,
            phone,
            mail
        );
    }
);