import React from 'react';
import styles from './File.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { loadFile } from './thunks/loadFile';
import LocalProgressBar from '../common/LocalProgressBar';
import { fileDownloadStateEnum } from './enum/fileDownloadStateEnum';
import { fileSelector } from './fileSlice';

const FileFile = ({item}) => {

    const dispatch = useDispatch();
    const file = useSelector(fileSelector);

    let t = item.date.split(/[- :]/);
    let d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
    const date = new Intl.DateTimeFormat('cs-CZ', {year: 'numeric', month: 'numeric', day: 'numeric'}).format(d);

    const iconStyle = {
        "application/pdf": styles.pdf,
        "image/jpeg": styles.jpg,
        "image/png": styles.png,
        "application/msword": styles.doc,
        "application/vnd.ms-excel": styles.xls,
        "text/plain": styles.txt,
        "application/zip": styles.zip
    }[item.type];

    const calculateFileSize = (size) => {
        let i = Math.floor( Math.log(size) / Math.log(1024) );
        return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    };
    
    return (
        <li className={[styles.fileFile].join(' ')}>
            <div className={[styles.fileData, iconStyle !== undefined ? iconStyle : styles.unknown].join(' ')} onClick={() => dispatch(loadFile(item))}>
                <span className={styles.name}>{item.name}</span>
                <span className={styles.type}>{item.type}</span>
                <span className={styles.size}>{calculateFileSize(item.size)}</span>
                <span className={styles.date}>{date}</span>
            </div>
            { 
                file.fileDownloadState === fileDownloadStateEnum.RUNNING && 
                file.fileDownloadId === item.id &&
                
                <div className={styles.progressBarContainer}>
                    <LocalProgressBar size="small" progress={file.fileDownloadProgress} />
                </div>
            }
            { 
                file.fileDownloadState === fileDownloadStateEnum.RUNNING && 
                file.fileDownloadId !== item.id &&

                <div className={styles.curtainContainer}></div>
            }
        </li> 
    );
};

export default FileFile;