export const recipientPositionEnum = Object.freeze({
    ARCHITECT: 1,
    FINANCIAL_ANALYST: 2,
    IT: 3,
    LOGISTICIAN: 4,
    CEO: 5,
    FINANCE_MANAGER: 6,
    PROJECT_MANAGER: 7,
    CONSTRUCTION_MANAGER: 8,
    BUILDING_INSPECTOR: 9,
    BUILDER: 11,
    HEAD_OF_PRODUCTION: 12,
    REGIONAL_CEO: 13,
    LAWYER: 14,
    BUDGET_ANALYST: 15,
    ECONOM: 16,
    ACCOUNTING_ANALYST: 17,
    VIRTUAL: 18,
    FINANCE_MANAGER_ASSISTANT: 19,
    RECEPTIONIST: 20
});