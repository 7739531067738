import { createSlice } from '@reduxjs/toolkit';
import { sendMessage } from './thunks/sendMessage';
import { messageStateEnum } from './enum/messageStateEnum';


export const helplineSlice = createSlice({
    name: 'helpline',
    initialState : {
        messageState: messageStateEnum.EMPTY,
        message: ""
    },
    reducers: {
        setMessage: (state, action) => {
            state.message = action.payload;
        },
        setMessageState: (state, action) => {
            state.messageState = action.payload;
        },
        resetMessageState: (state) => {
            state.messageState = messageStateEnum.EMPTY;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendMessage.fulfilled, (state, action) => {
                state.messageState = messageStateEnum.SUCCESSFULLY_SENT;
                state.message = "";
            })
            .addCase(sendMessage.pending, (state, action) => {
                state.messageState = messageStateEnum.PENDING;
            })
            .addCase(sendMessage.rejected, (state, action) => {
                state.messageState = messageStateEnum.UNSUCCESSFULLY_SENT;
            });
    }
});

export const { 
    setMessageState, 
    resetMessageState,
    setMessage
} = helplineSlice.actions;

export const helplineSelector = (state) => state.helpline;

export default helplineSlice.reducer;