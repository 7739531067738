import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Harmonogram.module.css';
import { Link } from 'react-router-dom';


const HarmonogramHurdle = ({hurdle}) => {

    const { t } = useTranslation();

    return (
        <div className={[styles.card, styles.harmonogramHurdle].join(' ')}>
            <div className={styles.header}>
                <span className={styles.title}>{t("Mimořádná událost")}</span>
            </div>
            <div className={styles.content} dangerouslySetInnerHTML={{ __html: hurdle.text }} />
            <div className={styles.footer}>
                <Link className={styles.button} to={'/hurdle/' + hurdle.id}>
                    <span>{t("Zobrazit více")}</span>
                    <i></i>
                </Link>
            </div>
        </div>
    );
};

export default HarmonogramHurdle;