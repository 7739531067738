import { createSlice } from '@reduxjs/toolkit';
import { newReferenceStateEnum } from './enum/newReferenceStateEnum';
import { referenceListStateEnum } from './enum/referenceListStateEnum';
import { loadReferenceList } from './thunks/loadReferenceList';
import { sendReference } from './thunks/sendReference';


export const referenceSlice = createSlice({
    name: 'reference',
    initialState : {
        referenceListState: referenceListStateEnum.EMPTY,
        referenceList: null,
        newReferenceState: newReferenceStateEnum.EMPTY,
        newReference: {
            firstName: '',
            lastName: '',
            phone: '',
            mail: ''
        }
    },
    reducers: {
        setNewReference: (state, action) => {
            state.newReference.firstName = action.payload.firstName;
            state.newReference.lastName = action.payload.lastName;
            state.newReference.phone = action.payload.phone;
            state.newReference.mail = action.payload.mail;
        },
        resetNewReference: (state) => {
            state.newReferenceState = newReferenceStateEnum.EMPTY;
            state.newReference.firstName = '';
            state.newReference.lastName = '';
            state.newReference.phone = '';
            state.newReference.mail = '';
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadReferenceList.fulfilled, (state, action) => {
                state.referenceList = action.payload;
                state.referenceListState = referenceListStateEnum.READY;
            })
            .addCase(loadReferenceList.pending, (state, action) => {
                state.referenceListState = referenceListStateEnum.PENDING;
            })
            .addCase(loadReferenceList.rejected, (state, action) => {
                state.referenceListState = referenceListStateEnum.UNSUCCESSFULLY_LOADED;
            })
            .addCase(sendReference.fulfilled, (state, action) => {
                state.newReferenceState = newReferenceStateEnum.SUCCESSFULLY_SENT;
                state.newReference.firstName = '';
                state.newReference.lastName = '';
                state.newReference.phone = '';
                state.newReference.mail = '';
                state.referenceList = [...state.referenceList, action.payload];
            })
            .addCase(sendReference.pending, (state, action) => {
                state.newReferenceState = newReferenceStateEnum.PENDING;
            })
            .addCase(sendReference.rejected, (state, action) => {
                state.newReferenceState = newReferenceStateEnum.UNSUCCESSFULLY_SENT;
            });
    }
});

export const { 
    setNewReference,
    resetNewReference
} = referenceSlice.actions;

export const referenceSelector = (state) => state.reference;

export default referenceSlice.reducer;