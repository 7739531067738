import { createSlice } from '@reduxjs/toolkit';
import { loadMessageList } from './thunks/loadMessageList';
import { sendMessage } from './thunks/sendMessage';
import { loadRecipientList } from './thunks/loadRecipientList';
import { recipientListStateEnum } from './enum/recipientListStateEnum';
import { newMessageStateEnum } from './enum/newMessageStateEnum';
import { newMessageMessageStateEnum } from './enum/newMessageMessageStateEnum';
import { messageListStateEnum } from './enum/messageListStateEnum';


export const communicationSlice = createSlice({
    name: 'communication',
    initialState : {
        messageList: null,
        messageListState: messageListStateEnum.EMPTY,

        newMessageState: newMessageStateEnum.ACTION,
        newMessageRecipient: null,
        newMessageMessage: '',
        newMessageMessageState: newMessageMessageStateEnum.EMPTY,
        
        recipientList: null,
        recipientListState: recipientListStateEnum.EMPTY
    },
    reducers: {
        showNewMessageRecipient: (state) => {
            state.newMessageState = newMessageStateEnum.RECIPIENT;
        },
        selectNewMessageRecipient: (state, action) => {
            state.newMessageRecipient = action.payload;
            state.newMessageState = newMessageStateEnum.MESSAGE;
        },
        setNewMessageMessage: (state, action) => {
            state.newMessageMessage = action.payload;
        },
        resetNewMessage: (state) => {
            state.newMessageState = newMessageStateEnum.ACTION;
            state.newMessageRecipient = null;
            state.newMessageMessage = '';
            state.newMessageMessageState = newMessageMessageStateEnum.EMPTY;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadMessageList.fulfilled, (state, action) => {
                state.messageList = action.payload;
                state.messageListState = messageListStateEnum.READY;
            })
            .addCase(loadMessageList.pending, (state, action) => {
                state.messageListState = messageListStateEnum.PENDING;
            })
            .addCase(loadMessageList.rejected, (state, action) => {
                state.messageListState = messageListStateEnum.UNSUCCESSFULLY_LOADED;
            })
            .addCase(loadRecipientList.fulfilled, (state, action) => {
                state.recipientList = action.payload;
                state.recipientListState = recipientListStateEnum.READY;
            })
            .addCase(loadRecipientList.pending, (state, action) => {
                state.recipientListState = recipientListStateEnum.PENDING;
            })
            .addCase(loadRecipientList.rejected, (state, action) => {
                state.recipientListState = recipientListStateEnum.UNSUCCESSFULLY_LOADED;
            })
            .addCase(sendMessage.fulfilled, (state, action) => {
                state.newMessageMessageState = newMessageMessageStateEnum.SUCCESSFULLY_SENT;
                state.newMessageRecipient = null;
                state.messageList = [...state.messageList, action.payload];
            })
            .addCase(sendMessage.pending, (state, action) => {
                state.newMessageMessageState = newMessageMessageStateEnum.PENDING;
            })
            .addCase(sendMessage.rejected, (state, action) => {
                state.newMessageMessageState = newMessageMessageStateEnum.UNSUCCESSFULLY_SENT;
            });
    }
});

export const { 
    resetNewMessage, 
    showNewMessageRecipient, 
    selectNewMessageRecipient,
    setNewMessageMessage
} = communicationSlice.actions;

export const communicationSelector = (state) => state.communication;

export default communicationSlice.reducer;