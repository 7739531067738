import { createAsyncThunk } from '@reduxjs/toolkit';
import { postHelp } from '../../../api/postHelp';


export const sendMessage = createAsyncThunk(
    'helpline/sendMessage',
    async (message, { getState }) => {
        const state = getState();

        return postHelp(
            state.user.key, 
            message
        ).then(() => true);
    }
);
