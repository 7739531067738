import { createAsyncThunk } from '@reduxjs/toolkit';
import { loadHarmonogram } from '../../harmonogram/thunks/loadHarmonogram';
import { loadStateList } from '../../faze/thunks/loadStateList';
import { loadFileList } from '../../file/thunks/loadFileList';
import { loadMessageList } from '../../communication/thunks/loadMessageList';


export const loadAll = createAsyncThunk(
    'index/loadAll',
    async (arg, { getState, dispatch }) => {
        const state = getState();
        
        return Promise.all([
            dispatch(loadHarmonogram()),
            dispatch(loadStateList()),
            dispatch(loadFileList()),
            dispatch(loadMessageList())
        ]);
    }
);

