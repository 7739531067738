import React from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { programKindEnum } from '../user/enum/programKindEnum';
import { userSelector } from '../user/userSlice';
import styles from './Index.module.css';
import { t } from "i18next";
import { communicationSelector } from '../communication/communicationSlice';
import { messageDirectionEnum } from '../communication/enum/messageDirectionEnum';

const IndexCommunication = () => {

    const { t } = useTranslation();
    const communication = useSelector(communicationSelector);

    
    const lastIncomingMessage = communication.messageList.filter(message => message.direction === messageDirectionEnum.COMPANY_TO_CLIENT).pop();

    const formatDate = (date) => {
        return new Intl.DateTimeFormat('cs-CZ', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(new Date(date));
    };

    return (
        <>
            { lastIncomingMessage && 
                <div className={[styles.card, styles.communication].join(' ')}>
                    <div className={styles.header}>
                        <span className={styles.title}>{t("Zprávy")}</span>
                    </div>
                    <div className={styles.content}>
                        <span className={styles.name}>{lastIncomingMessage.member.name}</span>
                        <span className={styles.date}>{formatDate(lastIncomingMessage.date)}</span> <span className={styles.time}>12:00</span>
                        <p className={styles.text}>{lastIncomingMessage.text}</p>
                    </div>
                    <div className={styles.footer}>
                        <Link className={styles.button} to={'/communication'}>
                            <span>{t("Zobrazit zprávy")}</span>
                            <i></i>
                        </Link>
                    </div>
                </div>
            }
        </>
    );
};

export default IndexCommunication;