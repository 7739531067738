import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { delayKindEnum } from './enum/delayKindEnum';
import styles from './Harmonogram.module.css';
import {
    harmonogramSelector
} from './harmonogramSlice';

const HarmonogramSummary = () => {

    const { t } = useTranslation();
    const harmonogram = useSelector(harmonogramSelector);

    let summary = {
        clientDelay:    0,
        clientAdvance:  0,
        clientRestore:  0,
        companyDelay:   0,
        companyAdvance: 0,
        godClima:       0,
        godEvent:       0
    };

    harmonogram.harmonogram.forEach(state => {
        [...state.delay.client, ...state.delay.company, ...state.delay.god].forEach(delay => {
            if (delay.kind === delayKindEnum.CLIENT_DELAY)
                summary.clientDelay += delay.length;
            else if (delay.kind === delayKindEnum.CLIENT_ADVANCE)
                summary.clientAdvance += delay.length;
            else if (delay.kind === delayKindEnum.CLIENT_RESTORE)
                summary.clientRestore += delay.length;
            else if (delay.kind === delayKindEnum.COMPANY_DELAY)
                summary.companyDelay += delay.length;
            else if (delay.kind === delayKindEnum.COMPANY_ADVANCE)
                summary.companyAdvance += delay.length;
            else if (delay.kind === delayKindEnum.GOD_CLIMA)
                summary.godClima += delay.length;
            else if (delay.kind === delayKindEnum.GOD_EVENT)
                summary.godEvent += delay.length;
        });
    });

    return (
        // <div className={styles.harmonogramSummary}>
        //     <h2>{t("Sumarizace")}</h2>
        //     <div className={styles.company}>
        //         <h3>{t("Zpoždění na straně dodavatele")}</h3>
        //         <ul>
        //             <li>
        //                 <span className={styles.title}>{t("Zpoždění na straně dodavatele")}</span>
        //                 <span className={styles.value}>{summary.companyDelay}</span>
        //             </li>
        //             <li>
        //                 <span className={styles.title}>{t("Souhrn předčasně dokončených etap")}</span>
        //                 <span className={styles.value}>{-summary.companyAdvance}</span>
        //             </li>
        //             <li className={styles.summary}>
        //                 <span className={styles.title}>{t("Celkové zpoždění na straně dodavatele")}</span>
        //                 <span className={styles.value}>{summary.companyDelay + summary.companyAdvance}</span>
        //             </li>
        //         </ul>
        //     </div>

        //     <div className={styles.client}>
        //         <h3>{t("Zpoždění na straně klienta")}</h3>
        //         <ul>
        //             <li>
        //                 <span className={styles.title}>{t("Zpoždění na straně klienta")}</span>
        //                 <span className={styles.value}>{summary.clientDelay}</span>
        //             </li>

        //             { summary.clientRestore !== 0 && 
        //                 <li>
        //                     <span className={styles.title}>{t("Souhrn obnovení výroby")}</span>
        //                     <span className={styles.value}>{summary.clientRestore}</span>
        //                 </li>
        //             }

        //             <li>
        //                 <span className={styles.title}>{t("Souhrn předčasně dokončených etap")}</span>
        //                 <span className={styles.value}>{-summary.clientAdvance}</span>
        //             </li>

        //             <li className={styles.summary}>
        //                 <span className={styles.title}>{t("Celkové zpoždění na straně klienta")}</span>
        //                 <span className={styles.value}>{summary.clientDelay + summary.clientAdvance + summary.clientRestore}</span>
        //             </li>
        //         </ul>
        //     </div>

        //     { (summary.godClima + summary.godEvent) !== 0 &&
        //         <div className={styles.god}>
        //             <h3>{t("Zpoždění na straně vyšší moci")}</h3>
        //             <ul>
        //                 { summary.godClima !== 0 && 
        //                     <li>
        //                         <span className={styles.title}>{t("Zpoždění v klimaticky nevhodných podmínkách")}</span>
        //                         <span className={styles.value}>{summary.godClima}</span>
        //                     </li>
        //                 }
        //                 { summary.godEvent !== 0 &&
        //                     <li>
        //                         <span className={styles.title}>{t("Mimořádné události")}</span>
        //                         <span className={styles.value}>{summary.godEvent}</span>
        //                     </li>
        //                 }
        //                 { summary.godClima + summary.godEvent &&
        //                     <li className={styles.summary}>
        //                         <span className={styles.title}>{t("Celkové zpoždění na straně vyšší moci")}</span>
        //                         <span className={styles.value}>{summary.godClima + summary.godEvent}</span>
        //                     </li>
        //                 }
        //             </ul>
        //         </div>
        //     }
            
        // </div>

        <div className={styles.harmonogramSummary}>
            
            <div className={[styles.card, styles.company].join(' ')}>
                <div className={styles.header}>
                    <span className={styles.title}>{t("Zpoždění na straně dodavatele")}</span>
                </div>
                <div className={styles.content}>
                    <ul>
                        <li>
                            <span className={styles.title}>{t("Zpoždění na straně dodavatele")}</span>
                            <span className={styles.value}>{summary.companyDelay}</span>
                        </li>
                        <li>
                            <span className={styles.title}>{t("Souhrn předčasně dokončených etap")}</span>
                            <span className={styles.value}>{-summary.companyAdvance}</span>
                        </li>
                        <li className={styles.summary}>
                            <span className={styles.title}>{t("Celkové zpoždění na straně dodavatele")}</span>
                            <span className={styles.value}>{summary.companyDelay + summary.companyAdvance}</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div className={[styles.card, styles.client].join(' ')}>
                <div className={styles.header}>
                    <span className={styles.title}>{t("Zpoždění na straně klienta")}</span>
                </div>
                <div className={styles.content}>
                    <ul>
                        <li>
                            <span className={styles.title}>{t("Zpoždění na straně klienta")}</span>
                            <span className={styles.value}>{summary.clientDelay}</span>
                        </li>

                        { summary.clientRestore !== 0 && 
                            <li>
                                <span className={styles.title}>{t("Souhrn obnovení výroby")}</span>
                                <span className={styles.value}>{summary.clientRestore}</span>
                            </li>
                        }

                        <li>
                            <span className={styles.title}>{t("Souhrn předčasně dokončených etap")}</span>
                            <span className={styles.value}>{-summary.clientAdvance}</span>
                        </li>

                        <li className={styles.summary}>
                            <span className={styles.title}>{t("Celkové zpoždění na straně klienta")}</span>
                            <span className={styles.value}>{summary.clientDelay + summary.clientAdvance + summary.clientRestore}</span>
                        </li>
                    </ul>
                </div>
            </div>

            { (summary.godClima + summary.godEvent) !== 0 &&
                <div className={[styles.card, styles.god].join(' ')}>
                    <div className={styles.header}>
                        <span className={styles.title}>{t("Zpoždění na straně vyšší moci")}</span>
                    </div>
                    <div className={styles.content}>
                        <ul>
                            { summary.godClima !== 0 && 
                                <li>
                                    <span className={styles.title}>{t("Zpoždění v klimaticky nevhodných podmínkách")}</span>
                                    <span className={styles.value}>{summary.godClima}</span>
                                </li>
                            }
                            { summary.godEvent !== 0 &&
                                <li>
                                    <span className={styles.title}>{t("Mimořádné události")}</span>
                                    <span className={styles.value}>{summary.godEvent}</span>
                                </li>
                            }
                            { summary.godClima + summary.godEvent &&
                                <li className={styles.summary}>
                                    <span className={styles.title}>{t("Celkové zpoždění na straně vyšší moci")}</span>
                                    <span className={styles.value}>{summary.godClima + summary.godEvent}</span>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            }
            
        </div>
    );
}

export default HarmonogramSummary;