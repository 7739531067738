import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Faze.module.css';
import FazeStateList from './FazeStateList';


const Faze = () => {

    const { t } = useTranslation();

    return (
        <div className={styles.faze}>

            <div className={styles.motivation}>
            <h3>{t("Historie karet")}</h3>
                {t("LOAN_MOTIVATION").split("\n").map((line, index) => <p key={index}>{line}</p>)}
            </div>

            <div className={styles.layout}>
                <FazeStateList />
            </div>

        </div>
    );
}

export default Faze;