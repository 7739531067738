import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Reference.module.css';
import {
    resetNewReference,
    referenceSelector,
    setNewReference
} from './referenceSlice';
import LocalActivityIndicator from '../common/LocalActivityIndicator';
import { sendReference } from './thunks/sendReference';
import { newReferenceStateEnum } from './enum/newReferenceStateEnum';

const ReferenceNewReference = () => {

    let inputForAutoFocusRef = null;

    const { t } = useTranslation();
    const reference = useSelector(referenceSelector);
    const dispatch = useDispatch();

    const validate = () => {
        // if (reference.newReference.length > 5)
        //     return true;
        // if (reference.newReference.length > 5)
        //     return true;
        // return false;

        return true;
    };

    const submit = (event) => {
        
        event.preventDefault();
        
        // if (!validate()) {
        //     dispatch(setMessage(t("Vstupní data nejsou validní")));
        //     return false;
        // }

        dispatch(
            sendReference({
                firstName: reference.newReference.firstName, 
                lastName: reference.newReference.lastName,
                phone: reference.newReference.phone,
                mail: reference.newReference.mail
            })
        );

        return false;
    };

    const changeFirstName = (text) => {
        dispatch(setNewReference({
            ...reference.newReference,
            firstName: text
        }));
    };

    const changeLastName = (text) => {
        dispatch(setNewReference({
            ...reference.newReference,
            lastName: text
        }));
    };

    const changePhone = (text) => {
        dispatch(setNewReference({
            ...reference.newReference,
            phone: text
        }));
    };

    const changeMail = (text) => {
        dispatch(setNewReference({
            ...reference.newReference,
            mail: text
        }));
    };

    useEffect(
        () => {
			if (window.innerWidth > 768)
                inputForAutoFocusRef?.focus();
        }, 
        [dispatch, inputForAutoFocusRef]
    );

    return (
        <div className={styles.referenceNewReference}>
            { reference.newReferenceState === newReferenceStateEnum.UNSUCCESSFULLY_SENT &&
                <div className={[styles.messageBox, styles.negative].join(' ')}>
                    <p>{t('Nepodařilo se odeslat referenci, zkuste to prosím ještě jednou.')}</p>
                </div>
            }
            { reference.newReferenceState === newReferenceStateEnum.SUCCESSFULLY_SENT &&
                <div className={[styles.messageBox, styles.pozitive].join(' ')}>
                    <p>{t('Data byla úspěšně vložena')}</p>
                    <button className={styles.button} onClick={() => dispatch(resetNewReference())}>{t('Zavřít')}</button>
                </div>
            }
            { reference.newReferenceState !== newReferenceStateEnum.SUCCESSFULLY_SENT &&
                <div className={styles.referenceForm}>
                    <form onSubmit={(event) => submit(event)}>
                        <label>
                            {t("Jméno")}
                            <input type="text" onChange={(event) => changeFirstName(event.target.value)} value={reference.newReference.firstName} required minLength="3" ref={input => { inputForAutoFocusRef = input; }} />
                        </label>
                        <label>
                            {t("Příjmení")}
                            <input type="text" onChange={(event) => changeLastName(event.target.value)} value={reference.newReference.lastName} required minLength="3" />
                        </label>
                        <label>
                            {t("Telefon")}
                            <input type="tel" onChange={(event) => changePhone(event.target.value)} value={reference.newReference.phone} required minLength="3" pattern="(\+)*[0-9]{6,15}" />
                        </label>
                        <label>
                            {t("Mail")}
                            <input type="email" onChange={(event) => changeMail(event.target.value)} value={reference.newReference.mail} required minLength="3" />
                        </label>
                        <div className={styles.control}>
                            { validate() && 
                                <button className={styles.submit}>{t("Odeslat")}</button>
                            }
                            <button className={styles.cancel} onClick={() => dispatch(resetNewReference())}>{t("Zrušit")}</button>
                        </div>
                    </form>
                    { reference.newReferenceState === newReferenceStateEnum.PENDING &&
                        <div className={styles.activityIndicatorContainer}><LocalActivityIndicator size="small" /></div>
                    }
                </div>
            }
        </div>
    );
};


export default ReferenceNewReference;