export class LocalDate {
    
    constructor(date = null) {
        if (date == null)
            this.now()
        else
            this.set(date)
    }

    set(date) {
        this.date = new Date(date)

        return this
    }

    now() {
        this.date = new Date()

        return this
    }

    today() {
        this.date = new Date()
        this.date.setHours(0,0,0,0)

        return this
    }

    getDay() {
        return this.date.getDate()
    }

    getMonth() {
        return (this.date.getMonth() + 1)
    }

    getYear() {
        return (this.date.getFullYear())
    }

    getHour() {
        return this.date.getHours()
    }

    getMinute() {
        return this.date.getMinutes()
    }

    getSecond() {
        return this.date.getSeconds()
    }

    getUnixTime() {
        return this.date.getTime()
    }

    getDateObject() {
        return new Date(this.date.valueOf())
    }

    getDate() {
        return (
            this.getYear() 
            + '-' 
            + ('0' + this.getMonth()).slice(-2)
            + '-'
            + ('0' + this.getDay()).slice(-2)
        )
    }

    getTime() {
        return (
            ('0' + this.getHour()).slice(-2)
            + ':'
            + ('0' + this.getMinute()).slice(-2)
            + ':'
            + ('0' + this.getSecond()).slice(-2)
        )
    }

    getDateTime() {
        return (this.getDate() + ' ' + this.getTime())
    }

    addInterval(interval) {
        if (interval.year)
            this.date.setFullYear(this.date.getFullYear() + interval.year)
        if (interval.month)
            this.date.setMonth(this.date.getMonth() + interval.month)
        if (interval.day)
            this.date.setDate(this.date.getDate() + interval.day)
        if (interval.hour)
            this.date.setHours(this.date.getHours() + interval.hour)
        if (interval.minute)
            this.date.setMinutes(this.date.getMinutes() + interval.minute)
        if (interval.second)
            this.date.setSeconds(this.date.getSeconds() + interval.second)

        return this
    }

    copy(date) {
        this.date = new Date(date.getDateObject().valueOf())

        return this
    }

    dayDifference(date) {
        return Math.round(
            (this.getUnixTime() - date.getUnixTime()) / 86400000
        )
    }

    compare(date) {
        let timeThis = this.getUnixTime()
        let timeThat = date.getUnixTime()

		if (timeThis > timeThat)
			return 1
		else if (timeThis < timeThat)
			return -1
		else
			return 0
    }

    setYear(year) {
        this.date.setFullYear(year)

        return this
    }

    setMonth(month) {
        this.date.setMonth(month - 1)

        return this
    }

    setDay(day) {
        this.date.setDate(day)

        return this
    }

    setHour(hour) {
        this.date.setHours(hour)

        return this
    }

    setMinute(minute) {
        this.date.setMinutes(minute)

        return this
    }

    setSecond(second) {
        this.date.setSeconds(second)

        return this
    }
}

export const parseDate = (date) => {
    let days = String(date).split('-')
    return new Date(
        ...[
            parseInt(days[0]), 
            parseInt(days[1])-1, 
            parseInt(days[2]), 
            0, 
            0, 
            0
        ]
    )
}

export const parseDateTime = (date) => {
    date = String(date).split(' ')
    let days = String(date[0]).split('-')
    let hours = String(date[1]).split(':')
    return new Date(
        ...[
            parseInt(days[0]), 
            parseInt(days[1])-1, 
            parseInt(days[2]), 
            parseInt(hours[0]), 
            parseInt(hours[1]), 
            parseInt(hours[2])
        ]
    )
}