import React from 'react';
import { useSelector } from 'react-redux';
import styles from './Communication.module.css';
import {
    communicationSelector
} from './communicationSlice';
import CommunicationMessage from './CommunicationMessage';


const CommunicationMessageList = () => {

    const communication = useSelector(communicationSelector);

    return (
        <ul className={styles.communicationMessageList}>{
            communication.messageList.map((item) => (<CommunicationMessage item={item} key={item.id} />))
        }</ul>
    );
};


export default CommunicationMessageList;