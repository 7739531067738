import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMemberList } from '../../../api/getMemberList';


export const loadRecipientList = createAsyncThunk(
    'communication/loadRecipientList',
    async (arg, { getState }) => {
        const state = getState();
        
        return getMemberList(state.user.key);
    }
);