import axios from "axios";
import i18next from "i18next";


export const postLogin = (user, password) => axios({
        url: 'https://api.es-europe.eu/login',
        method: 'POST',
        data: {
            user: user,
            password: password,
            platform: 1,
            platformSpecificCode: ""
        }
    })
    .then(response => response.data.data)
    .then(item => String(item.apiKey))
    .catch((error) => Promise.reject(i18next.t('Nepodařilo se přihlásit uživatele')));