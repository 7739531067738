import { createAsyncThunk } from '@reduxjs/toolkit';
import { postMessage } from '../../../api/postMessage';


export const sendMessage = createAsyncThunk(
    'communication/sendMessage',
    async ({ recipient, message }, { getState }) => {
        const state = getState();

        return postMessage(state.user.key, message, recipient);
    }
);