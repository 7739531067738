import axios from "axios";
import i18next from "i18next";


export const getCardList = (apiKey) => axios({
        url: 'https://api.es-europe.eu/card',
        method: 'GET',
        headers: {
            token: apiKey
        }
    })
    .then(response => response.data.data)
    .then(cards => cards.stateList.map(
        (state) => ({
            id:             Number(state.id),
            cardList:       state.cardList.map(
                (card) => ({
                    id:             Number(card.id),
                    name:           String(card.name),
                    content:        String(card.content)
                })
            )
        }))
    )
    .catch(error => Promise.reject(i18next.t('Nepodařilo se načíst seznam karet')));