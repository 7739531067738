import React from 'react';
import { useSelector } from 'react-redux';
import styles from './File.module.css';
import {
    fileSelector
} from './fileSlice';
import FileFile from './FileFile';
import { useTranslation } from 'react-i18next';


const FileFileList = () => {

    const { t } = useTranslation();
    const file = useSelector(fileSelector);

    return (

        <div className={[styles.fileFileList, styles.card].join(' ')}>
            <div className={styles.header}>
                <span className={styles.title}>{t("Dokumenty")}</span>
            </div>
            <div className={styles.content}>
                <ul className={styles.fileList}>{
                    file.fileList.map((item) => (<FileFile item={item} key={item.id} />))
                }</ul>
            </div>
        </div>
    );
};


export default FileFileList;