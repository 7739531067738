import React from 'react';
import Hurdle from './Hurdle';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../common/BreadCrumbs';
import Page from '../common/Page';
import { useLocation } from 'react-router-dom';

const HurdlePage = () => {

    const { t } = useTranslation();
    const location = useLocation();

    return (
        <Page>
            <BreadCrumbs stack={[
                {title: t("Domů"), path: "/"},
                {title: t("Harmonogram"), path: ".."},
                {title: t("Detail"), path: location.pathname}
            ]} />
            <Hurdle />
        </Page>
    );
};

export default HurdlePage;