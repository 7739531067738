import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Communication.module.css';
import {
    resetNewMessage,
    communicationSelector,
    selectNewMessageRecipient
} from './communicationSlice';
import LocalActivityIndicator from '../common/LocalActivityIndicator';
import { loadRecipientList } from './thunks/loadRecipientList';
import { recipientListStateEnum } from './enum/recipientListStateEnum';
import { recipientPositionEnum } from './enum/recipientPositionEnum';

const Recipient = ({recipient}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const position = {
        [recipientPositionEnum.FINANCE_MANAGER]: t("manažer financí"),
        [recipientPositionEnum.PROJECT_MANAGER]: t("manažer projektu"),
        [recipientPositionEnum.CONSTRUCTION_MANAGER]: t("manažer stavby"),
        [recipientPositionEnum.ECONOM]: t("ekonom"),
        [recipientPositionEnum.FINANCIAL_ANALYST]: t("finanční analytik"),
        [recipientPositionEnum.CEO]: t("ředitel společnosti"),
        [recipientPositionEnum.HEAD_OF_PRODUCTION]: t("výrobní ředitel"),
        [recipientPositionEnum.FINANCE_MANAGER_ASSISTANT]: t("asistent manažera financí"),
        [recipientPositionEnum.LAWYER]: t("právník"),
        [recipientPositionEnum.IT]: t("informatika")
    }[recipient.position];

    return (
        <li onClick={() => dispatch(selectNewMessageRecipient(recipient.id))}>
            <span className={styles.image}>
                <img src={recipient.imageUrl} alt={recipient.name} />
            </span>
            <span className={styles.data}>
                <span className={styles.name}>{recipient.name}</span>
                <span className={styles.position}>{position !== undefined ? position : t("nezařazen")}</span>
            </span>
        </li>
    );
};

const CommunicationNewMessageRecipient = () => {

    const { t } = useTranslation();
    const communication = useSelector(communicationSelector);
    const dispatch = useDispatch();

    useEffect(
        () => {
            if (communication.recipientListState === recipientListStateEnum.UNSUCCESSFULLY_LOADED)
                dispatch(loadRecipientList())
        }, 
        [dispatch, communication]
    );

    return (
        <div className={styles.recipient}>
            { communication.recipientListState === recipientListStateEnum.UNSUCCESSFULLY_LOADED ?
                <div className={[styles.messageBox, styles.negative].join(' ')}>
                    <p>{t('Nepodařilo se načíst seznam adresátů, zkuste to prosím později.')}</p>
                    <button className={styles.reload} onClick={() => dispatch(loadRecipientList())}>{t('Načíst znovu')}</button>
                    <button className={styles.cancel} onClick={() => dispatch(resetNewMessage())}>{t('Zrušit')}</button>
                </div>
            :
                <div className={styles.recipientList}>
                    { communication.recipientListState === recipientListStateEnum.READY &&
                        <ul>
                            { communication.recipientList.map(
                                (recipient) => (<Recipient recipient={recipient} key={recipient.id} />)
                                ) 
                            }
                        </ul>
                    }
                    <button className={styles.cancel} onClick={() => dispatch(resetNewMessage())}>{t("Zrušit")}</button>
                    { communication.recipientListState === recipientListStateEnum.PENDING &&
                        <div className={styles.activityIndicatorContainer}><LocalActivityIndicator size="small" /></div>
                    }
                </div>
            }
            
        </div>
    );
};


export default CommunicationNewMessageRecipient;