import React from 'react';
import {
	BrowserRouter,
	Route,
	Routes,
	Navigate
} from 'react-router-dom';
import HarmonogramPage from '../harmonogram/HarmonogramPage';
import HelplinePage from '../helpline/HelplinePage';
import FilePage from '../file/FilePage';
import LoanPage from '../loan/LoanPage';
import FazePage from '../faze/FazePage';
import HurdlePage from '../harmonogram/HurdlePage';
import CardPage from '../faze/CardPage';
import ReferencePage from '../reference/ReferencePage';
import ServicePage from '../service/ServicePage';
import StatePage from '../harmonogram/StatePage';
import CommunicationPage from '../communication/CommunicationPage';
import IndexPage from '../index/IndexPage';
import ScrollToTop from './ScrollToTop';


const Navigation = () => {

	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/harmonogram" element={<HarmonogramPage />} />
				<Route path="/state/:id" element={<StatePage />} />
				<Route path="/hurdle/:id" element={<HurdlePage />} />
				<Route path="/communication" element={<CommunicationPage />} />
				<Route path="/helpline" element={<HelplinePage />} />
				<Route path="/file" element={<FilePage />} />
				<Route path="/loan" element={<LoanPage />} />
				<Route path="/faze" element={<FazePage />} />
				<Route path="/faze/:id" element={<CardPage />} />
				<Route path="/reference" element={<ReferencePage />} />
				<Route path="/service" element={<ServicePage />} />
				<Route path="/" element={<IndexPage />} />
				<Route path="/access/:key" element={<Navigate to="/" replace />} />
				<Route path="/password/:key" element={<Navigate to="/" replace />} />
			</Routes>
		</BrowserRouter>
	);
}

export default Navigation;