const storageKey = 'com.ekonomickestavby.client.key';

export const setStorageApiKey = (apiKey) => {
    // localStorage.setItem(storageKey, apiKey);
    sessionStorage.setItem(storageKey, apiKey);

    // axios({
    //     url: 'https://sec.es-europe.eu/store',
    //     method: 'POST',
    //     data: {
    //         key: apiKey
    //     }
    // })
    // .then()
    // .catch(error => Promise.reject(i18next.t('Nepodařilo se odeslat zprávu')))
};

export const getStorageApiKey = () => {
    // return localStorage.getItem(storageKey);
    return sessionStorage.getItem(storageKey);
};

export const resetStorageApiKey = () => {
    // localStorage.removeItem(storageKey);
    sessionStorage.removeItem(storageKey);
};