import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './Communication.module.css';
import {
    showNewMessageRecipient
} from './communicationSlice';


const CommunicationNewMessageAction = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <div className={styles.action}>
            <button onClick={() => dispatch(showNewMessageRecipient())} className={styles.send}>{t("Napsat novou zprávu")}</button>
        </div>
    );
};


export default CommunicationNewMessageAction;