import { createSlice } from '@reduxjs/toolkit';
import { complaintSeverityEnum } from './enum/complaintSeverityEnum';
import { complaintStateEnum } from './enum/complaintStateEnum';
import { sendComplaint } from './thunks/sendComplaint';


export const serviceSlice = createSlice({
    name: 'service',
    initialState : {
        complaintState: complaintStateEnum.EMPTY,
        complaintSeverity: complaintSeverityEnum.SERVICE,
        complaintMessage: ''
    },
    reducers: {
        setComplaintSeverity: (state, action) => {
            state.complaintSeverity = action.payload;
        },
        setComplaintMessage: (state, action) => {
            state.complaintMessage = action.payload;
        },
        resetComplaint: (state) => {
            state.complaintSeverity = complaintSeverityEnum.SERVICE;
            state.complaintMessage = '';
            state.complaintState = complaintStateEnum.EMPTY;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendComplaint.fulfilled, (state, action) => {
                state.complaintState = complaintStateEnum.SUCCESSFULLY_SENT;
                state.complaintSeverity = complaintSeverityEnum.SERVICE;
                state.complaintMessage = '';
            })
            .addCase(sendComplaint.pending, (state, action) => {
                state.complaintState = complaintStateEnum.PENDING;
            })
            .addCase(sendComplaint.rejected, (state, action) => {
                state.complaintState = complaintStateEnum.UNSUCCESSFULLY_SENT;
            });
    }
});

export const { 
    setComplaintSeverity,
    setComplaintMessage,
    resetComplaint
} = serviceSlice.actions;

export const serviceSelector = (state) => state.service;

export default serviceSlice.reducer;