import React from 'react';
import styles from './Common.module.css';
import { Link, useLocation, useNavigate } from "react-router-dom";

const BreadCrumbItem = ({item}) => {

    const location = useLocation();
    const navigate = useNavigate();

    const handleGoBack = (event) => {
        event.preventDefault();
        navigate(-1);
    };

    if (item.path === '..')
        return (
            <li className={styles.regularItem}><Link to={item.path} onClick={handleGoBack}>{item.title}</Link></li>
        );
    else if (item.path !== location.pathname)
        return (
            <li className={styles.regularItem}><Link to={item.path}>{item.title}</Link></li>
        );
    else
        return (
            <li className={styles.currentItem}>{item.title}</li>
        );
};

const BreadCrumbs = ({stack}) => {

    return (
        <div className={styles.breadcrumbs}>
            <ul>
                { stack.map ((item) => (<BreadCrumbItem item={item} key={item.path} />))}
            </ul>
        </div>
    );
};

export default BreadCrumbs;