import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Helpline.module.css';
import {
    helplineSelector, 
    resetMessageState,
    setMessage
} from './helplineSlice';
import { userSelector } from '../user/userSlice';
import LocalActivityIndicator from '../common/LocalActivityIndicator';
import { messageStateEnum } from './enum/messageStateEnum';
import { sendMessage } from './thunks/sendMessage';
import image_esc from './images/director_esc.jpg';
import image_ess from './images/director_ess.jpg';
import image_esp from './images/director_esp.jpg';
import { companyEnum } from '../user/enum/companyEnum';


const Helpline = () => {

    let textAreaRef = null;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const helpline = useSelector(helplineSelector);
    const user = useSelector(userSelector);

    const validate = () => {
        if (helpline.message.length > 5)
            return true;
        return false;
    };

    const submit = () => {
        
        // if (!validate()) {
        //     dispatch(setMessage(t("Vstupní data nejsou validní")));
        //     return false;
        // }

        dispatch(sendMessage(helpline.message));

        return false;
    };

    const change = (text) => {
        dispatch(setMessage(text));
    };

    const directorImage = {
        [companyEnum.ESC]: image_esc,
        [companyEnum.ESS]: image_ess,
        [companyEnum.ESP]: image_esp
    }[user.user.company];

    const directorName = {
        [companyEnum.ESC]: "David Mencl",
        [companyEnum.ESS]: "Aleš Urbánek",
        [companyEnum.ESP]: "Michal Jalovecký"
    }[user.user.company];

    useEffect(
        () => {
			if (window.innerWidth > 768)
                textAreaRef?.focus();
        }, 
        [dispatch, textAreaRef]
    );

    return (
        <div className={styles.helpline}>
            
            <div className={styles.motivation}>
            <h3>{t("Nejste spokojeni?")}</h3>
                {t("HELPLINE_MOTIVATION").split("\n").map((line, index) => <p key={index}>{line}</p>)}
            </div>

            <div className={styles.newMessage}>
                <div className={styles.director}>
                    <img src={directorImage} alt={directorName} />
                    <span><strong>{directorName}</strong>, {t("generální ředitel společnosti Ekonomické stavby v ČR")}</span>
                </div>
                { helpline.messageState === messageStateEnum.UNSUCCESSFULLY_SENT &&
                    <div className={[styles.messageBox, styles.negative].join(' ')}>
                        <p>{t('Nepodařilo se odeslat zprávu, zkuste to prosím ještě jednou.')}</p>
                    </div>
                }
                { helpline.messageState === messageStateEnum.SUCCESSFULLY_SENT &&
                    <div className={[styles.messageBox, styles.pozitive].join(' ')}>
                        <p>{t('Data byla úspěšně vložena')}</p>
                        <button className={styles.button} onClick={() => dispatch(resetMessageState())}>{t('Zavřít')}</button>
                    </div>
                }
                { helpline.messageState !== messageStateEnum.SUCCESSFULLY_SENT &&
                    <div className={styles.layout}>
                        <p className={styles.title}>{t("Zpráva")}</p>
                        <textarea name="message" onChange={(event) => change(event.target.value)} placeholder={t("Sem napište stručně vaší zprávu")} value={helpline.message} required ref={textArea => { textAreaRef = textArea; }} />
                        <div className={styles.control}>
                            { validate() && 
                                <button className={styles.submit} onClick={() => submit()}>{t("Odeslat")}</button>
                            }
                        </div>
                        { helpline.messageState === messageStateEnum.PENDING &&
                            <div className={styles.activityIndicatorContainer}><LocalActivityIndicator size="small" /></div>
                        }
                    </div>
                }
            </div>
        </div>
    );
}

export default Helpline;