import React from 'react';
import Loan from './Loan';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../common/BreadCrumbs';
import Page from '../common/Page';

const LoanPage = () => {

    const { t } = useTranslation();

    return (
        <Page>
            <BreadCrumbs stack={[
                {title: t("Domů"), path: "/"},
                {title: t("Půjčky"), path: "/loan"}
            ]} />
            <Loan />
        </Page>
    );
};

export default LoanPage;