import axios from "axios";
import i18next from "i18next";


export const postPasswordRequest = (invitationKey) => axios({
        url: 'https://api.es-europe.eu/password_request',
        method: 'POST',
        data: {
            invitationKey: invitationKey
        }
    })
    .then(response => response.data.data)
    .then(item => String(item.password))
    .catch(error => {
        if (error.response && error.response.status === 403)
            return Promise.reject(i18next.t('Nepodařilo se načíst nové heslo - heslo již bylo vygenerováno, kontaktujte prosím svého manažera'))
        return Promise.reject(i18next.t('Nepodařilo se načíst nové heslo - zkuste to prosím znovu'));
    });