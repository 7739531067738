import axios from "axios";
import i18next from "i18next";


export const getLoanList = (apiKey) => axios({
        url: 'https://api.es-europe.eu/loan',
        method: 'GET',
        headers: {
            token: apiKey
        }
    })
    .then(response => response.data.data)
    .then(loans => loans.loanList.map(
        (loan) => ({
            id:             Number(loan.id),
            number:         String(loan.number),
            startAmount:    Number(loan.startAmount),
            startDate:      String(loan.startDate),
            endDate:        String(loan.endDate),
            currentAmount:  Number(loan.currentAmount),
            currency:       String(loan.currency)
        }))
    )
    .catch(error => Promise.reject(i18next.t('Nepodařilo se načíst seznam půjček')));