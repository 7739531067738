import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLoanList } from '../../../api/getLoanList';


export const loadLoanList = createAsyncThunk(
    'loan/loadLoanList',
    async (arg, { getState }) => {
        const state = getState();
        
        return getLoanList(state.user.key);
    }
);
