import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Service.module.css';
import ServiceNewComplaint from './ServiceNewComplaint';


const Service = () => {

    const { t } = useTranslation();
    

    return (
        <div className={styles.service}>
            <div className={styles.motivation}>
                <h3>{t("Servis nemovitosti")}</h3>
                {t("SERVICE_MOTIVATION").split("\n").map((line, index) => <p key={index}>{line}</p>)}
            </div>
            <div className={styles.layout}>
                <ServiceNewComplaint />
            </div>
        </div>
    );
}

export default Service;