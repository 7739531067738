import React from 'react';
import styles from './User.module.css';
import Login from './Login';

const LoginPage = () => {

    return (
        <div className={styles.loginPage}>
            <Login />
        </div>
    );
};

export default LoginPage;