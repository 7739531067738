import { createSlice } from '@reduxjs/toolkit';
import { harmonogramStateEnum } from './enum/harmonogramStateEnum';
import { processHarmonogram } from './lib/harmonogram';
import { loadHarmonogram } from './thunks/loadHarmonogram';


export const harmonogramSlice = createSlice({
    name: 'harmonogram',
    initialState : {
        harmonogram: null,
        harmonogramState: harmonogramStateEnum.EMPTY
    },
    reducers: {
        // setHarmonogram: (state, action) => {
        //     state.harmonogram = {
        //         id: action.payload.id,
        //         name: action.payload.name,
        //         number: action.payload.number
        //     };
        // },
        // setMessage: (state, action) => {
        //     state.message = action.payload;
        // },
        // resetMessage: (state) => {
        //     state.message = null;
        // }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadHarmonogram.fulfilled, (state, action) => {
                state.harmonogram = processHarmonogram(action.payload);
                state.harmonogramState = harmonogramStateEnum.READY;
            })
            .addCase(loadHarmonogram.pending, (state, action) => {
                state.harmonogramState = harmonogramStateEnum.PENDING;
            })
            .addCase(loadHarmonogram.rejected, (state, action) => {
                console.log(action);
                state.harmonogramState = harmonogramStateEnum.UNSUCCESSFULLY_LOADED;
            })
    }
});

// export const { setUser, resetMessage, setMessage } = harmonogramSlice.actions;

export const harmonogramSelector = (state) => state.harmonogram;

export default harmonogramSlice.reducer;