import axios from "axios";
import i18next from "i18next";


export const getFileList = (apiKey) => axios({
        url: 'https://api.es-europe.eu/file',
        method: 'GET',
        headers: {
            token: apiKey
        }
    })
    .then(response => response.data.data)
    .then(files => files.fileList.map(
        (file) => ({
            id:             Number(file.id),
            name:           String(file.name),
            size:           Number(file.size),
            type:           String(file.type),
            note:           String(file.note),
            date:           String(file.date)
        }))
    )
    .catch(error => Promise.reject(i18next.t('Nepodařilo se načíst seznam souborů')));