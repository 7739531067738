import { createAsyncThunk } from '@reduxjs/toolkit';
import { getReferenceList } from '../../../api/getReferenceList';


export const loadReferenceList = createAsyncThunk(
    'reference/loadReferenceList',
    async (arg, { getState }) => {
        const state = getState();
        
        return getReferenceList(state.user.key);
    }
);

