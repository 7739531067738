import React from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { programKindEnum } from '../user/enum/programKindEnum';
import { userSelector } from '../user/userSlice';
import styles from './Index.module.css';
import { harmonogramSelector } from "../harmonogram/harmonogramSlice";
import { stateStageEnum } from "../harmonogram/enum/stateStageEnum";
import { stateKindEnum } from "../harmonogram/enum/stateKindEnum";

const IndexHarmonogramState = ({state}) => {

    const { t } = useTranslation();

    const stageStyle = ({
        [stateStageEnum.CURRENT]: styles.current,
        [stateStageEnum.FUTURE]:  styles.future
    })[state.stage];

    const date = new Intl.DateTimeFormat('cs-CZ', {year: 'numeric', month: 'numeric', day: 'numeric'}).format(new Date(state.date)).replace(/ /g, "");

    return (
        <li className={stageStyle}>
            <span className={styles.date}>{date}</span>
            <span className={styles.title}>{state.title}</span>
            { state.kind === stateKindEnum.CLIENT && 
                <span className={styles.warning}>{t("Etapa vyžaduje vaší součinnost")}</span>
            }
        </li>
    );
};

const IndexHarmonogram = () => {

    const { t } = useTranslation();
    const harmonogram = useSelector(harmonogramSelector);

    const stateList = harmonogram.harmonogram.filter(
        state => [stateStageEnum.CURRENT, stateStageEnum.FUTURE].includes(state.stage)
    ).slice(0, 3);

    return (
        <div className={[styles.card, styles.harmonogram].join(' ')}>
            <div className={styles.header}>
                <span className={styles.title}>{t("Harmonogram")}</span>
            </div>
            <div className={styles.content}>
                <ul>
                    { stateList.map((state, index) => (
                        <IndexHarmonogramState key={index} state={state} />
                    ))}

                    {/* 
                    <li className={styles.current}>
                        <span className={styles.date}>23.12.2024</span>
                        <span className={styles.title}>Zahájení stavby</span>
                        <span className={styles.warning}>Etapa vyžaduje vaší součinnost</span>
                    </li>
                    <li className={styles.current}>
                        <span className={styles.date}>23.12.2024</span>
                        <span className={styles.title}>Zahájení stavby</span>
                        <span className={styles.warning}>Etapa vyžaduje vaší součinnost</span>
                    </li>
                    <li className={styles.future}>
                        <span className={styles.date}>23.12.2024</span>
                        <span className={styles.title}>Pokračoání stavby stavby ve velkém stylu a další možnosti</span>
                    </li>
                    <li className={styles.future}>
                        <span className={styles.date}>23.12.2024</span>
                        <span className={styles.title}>Další pokračoání stavby</span>
                    </li> */}
                </ul>
            </div>
            <div className={styles.footer}>
                <Link className={styles.button} to={'/harmonogram'}>
                    <span>{t("Zobrazit celý harmonogram")}</span>
                    <i></i>
                </Link>
            </div>
        </div>
    );
};

export default IndexHarmonogram;