import { createAsyncThunk } from "@reduxjs/toolkit";
import { postLogout } from "../../../api/postLogout";
import { resetStorageApiKey } from "../../../storage/localStorage";
import { resetUser } from "../userSlice";


export const logout = createAsyncThunk(
    'user/logout',
    
    async (arg, { dispatch, getState }) => {
        const state = getState();

        return postLogout(state.user.key)
            .then(() => true)
            .finally(() => {
                resetStorageApiKey();
            })
            .finally(() => {
                dispatch(resetUser());
            });
    }
);