import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Communication.module.css';
import {
    communicationSelector
} from './communicationSlice';
import LocalActivityIndicator from '../common/LocalActivityIndicator';
import CommunicationMessageList from './CommunicationMessageList';
import CommunicationNewMessage from './CommunicationNewMessage';
import { loadMessageList } from './thunks/loadMessageList';
import { loadRecipientList } from './thunks/loadRecipientList';
import { messageListStateEnum } from './enum/messageListStateEnum';
import { recipientListStateEnum } from './enum/recipientListStateEnum';


const Communication = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const communication = useSelector(communicationSelector);

    useEffect(
        () => {
            if (communication.messageListState === messageListStateEnum.EMPTY)
                dispatch(loadMessageList());
            if (communication.recipientListState === recipientListStateEnum.EMPTY)
                dispatch(loadRecipientList());
        }, 
        [dispatch, communication]
    );
    

    return (
        <div className={styles.communication}>
            
            <div className={[styles.motivation, styles.card].join(' ')}>
                <div className={styles.content}>
                    {t("COMMUNICATION_MOTIVATION").split("\n").map((line, index) => <p key={index}>{line}</p>)}
                </div>
            </div>

            <div className={styles.layout}>
                <CommunicationNewMessage />
                { communication.messageListState === messageListStateEnum.UNSUCCESSFULLY_LOADED &&
                    <div className={[styles.messageBox, styles.negative].join(' ')}>
                        <p>{t('Nepodařilo se načíst data, zkuste to prosím ještě jednou.')}</p>
                        <button className={styles.button} onClick={() => dispatch(loadMessageList())}>{t('Načíst znovu')}</button>
                    </div>
                }
                { communication.messageListState === messageListStateEnum.PENDING &&
                    <div className={styles.activityIndicatorContainer}><LocalActivityIndicator size="small" /></div>
                }
                { communication.messageListState === messageListStateEnum.READY &&
                    <CommunicationMessageList />
                }
            </div>
        </div>
    );
}

export default Communication;