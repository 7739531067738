import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Harmonogram.module.css';


const HurdleContent = ({hurdle}) => {

    const { t } = useTranslation();
    
    const formatDate = (date) => new Intl.DateTimeFormat('cs-CZ', {year: 'numeric', month: 'numeric', day: 'numeric'}).format(new Date(date)).replace(/ /g, "");

    return (
        <div className={[styles.layout, styles.hurdleContent].join(' ')}>

            <div className={[styles.card, styles.hurdle].join(' ')}>
                <div className={styles.header}>
                    <span className={styles.title}>{t("Mimořádná událost")}</span>
                </div>
                <div className={styles.content} dangerouslySetInnerHTML={{ __html: hurdle.text }} />
            </div>

            <div className={[styles.card, styles.term].join(' ')}>
                <div className={styles.header}>
                    <span className={styles.title}>{t("Termín")}</span>
                </div>
                <div className={styles.content}>
                    <p>{formatDate(hurdle.from)} - {formatDate(hurdle.to)}</p>
                </div>
            </div>

            <div className={[styles.card, styles.note].join(' ')}>
                <div className={styles.header}>
                    <span className={styles.title}>{t("Poznámka")}</span>
                </div>
                <div className={styles.content}>
                    <p>{hurdle.note}</p>
                </div>
            </div>

        </div>
    );
}

export default HurdleContent;