import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationCz from './translation/cz.json'
import translationSk from './translation/sk.json'
import translationPl from './translation/pl.json'
import translationEn from './translation/en.json'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  cz: {
    translation: translationCz
  },
  sk: {
    translation: translationSk
  },
  pl: {
    translation: translationPl
  },
  en: {
    translation: translationEn
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "cz",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;