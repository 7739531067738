import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './Faze.module.css';
import {
    fazeSelector
} from './fazeSlice';
import LocalActivityIndicator from '../common/LocalActivityIndicator';
import { loadStateList } from './thunks/loadStateList';
import { stateListStateEnum } from './enum/stateListStateEnum';
import CardContent from './CardContent';


const Card = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const faze = useSelector(fazeSelector);
    const { id } = useParams();

    const getCurrentCard = (stateList, id) => {
        let foundState = stateList.find(state => {
            return state.cardList.find(card => card.id === Number(id));
        });
    
        return foundState.cardList.find(card => card.id === Number(id));
    };

    useEffect(
        () => {
            if (faze.stateListState === stateListStateEnum.EMPTY)
                dispatch(loadStateList());
        }, 
        [dispatch, faze]
    );
    
    return (
        <div className={styles.state}>
            { faze.stateListState === stateListStateEnum.UNSUCCESSFULLY_LOADED &&
                <div className={[styles.messageBox, styles.negative].join(' ')}>
                    <p>{t('Nepodařilo se načíst data, zkuste to prosím ještě jednou.')}</p>
                    <button className={styles.button} onClick={() => dispatch(loadStateList())}>{t('Načíst znovu')}</button>
                </div>
            }
            { faze.stateListState === stateListStateEnum.PENDING &&
                <div className={styles.activityIndicatorContainer}><LocalActivityIndicator size="small" /></div>
            }
            { faze.stateListState === stateListStateEnum.READY &&
                <CardContent card={getCurrentCard(faze.stateList, id)} />
            }
        </div>
    );
}

export default Card;