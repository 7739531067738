import React from 'react';
import styles from './Communication.module.css';
import { messageDirectionEnum } from './enum/messageDirectionEnum';
import image_client from './images/client.svg';
import { useTranslation } from 'react-i18next';
import { messageKindEnum } from './enum/messageKindEnum';


const CommunicationMessage = ({item}) => {

    const { t } = useTranslation();
    const date = new Intl.DateTimeFormat('cs-CZ', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(new Date(item.date));
    // const date = new Intl.DateTimeFormat('cs-CZ', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(new Date(item.date));

    if (item.direction === messageDirectionEnum.CLIENT_TO_COMPANY)
        return (
            <li className={[styles.message, styles.client].join(" ")}>
                <span className={styles.image}><img src={image_client} alt={t("klient")} /></span>
                <p>{item.text}</p>
                <span className={styles.date}>{date} &gt; {item.member.name}</span>
            </li> 
        );
    else if (item.kind === messageKindEnum.REGULAR)
        return (
            <li className={[styles.message, styles.company].join(" ")}>
                <span className={styles.image}><img src={item.member.imageUrl} alt={item.member.name} /></span>
                <p>{item.text}</p>
                <span className={styles.date}>{date} &lt; {item.member.name}</span>
            </li>
        );
    else
        return (
            <li className={[styles.message, styles.company].join(" ")}>
                <span className={styles.image}><img src={item.member.imageUrl} alt={item.member.name} /></span>
                <p><span className={styles.temporary}>{t("dočasná")}</span><br />{item.text}</p>
                <span className={styles.date}>{date} &lt; {item.member.name}</span>
            </li>
        );
};

export default CommunicationMessage;