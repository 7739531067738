import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCardList } from '../../../api/getCardList';


export const loadStateList = createAsyncThunk(
    'faze/loadStateList',
    async (arg, { getState }) => {
        const state = getState();
        
        return getCardList(state.user.key);
    }
);
