import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocalDate } from '../../lib/localDate';
import styles from './Loan.module.css';

const LoanLoan = ({loan}) => {

    const { t } = useTranslation();

    const startDate = new Intl.DateTimeFormat('cs-CZ', {year: 'numeric', month: 'numeric', day: 'numeric'}).format(new Date(loan.startDate)).replace(/ /g, "");
    const endDate = new Intl.DateTimeFormat('cs-CZ', {year: 'numeric', month: 'numeric', day: 'numeric'}).format(new Date(loan.endDate)).replace(/ /g, "");

    let stateStyle = styles.running;
    if (loan.currentAmount < 5)
        stateStyle = styles.paid;
    else if ((new LocalDate(loan.endDate)).compare(new LocalDate()) === 1)
        stateStyle = styles.expired;

    return (

        <div className={[styles.loanLoan, stateStyle, styles.card].join(" ")}>
            <div className={styles.header}>
                <span className={[styles.number, styles.line].join(' ')}>
                    <span className={styles.title}>{t('Číslo půjčky')}</span>
                    <span className={styles.value}>{loan.number}</span>
                </span>
            </div>
            <div className={styles.content}>
                <span className={[styles.startAmount, styles.line].join(' ')}>
                    <span className={styles.title}>{t('Počáteční jistina')}</span>
                    <span className={styles.value}>{loan.startAmount} {loan.currency}</span>
                </span>
                <span className={[styles.startDate, styles.line].join(' ')}>
                    <span className={styles.title}>{t('Datum vypůjčení')}</span>
                    <span className={styles.value}>{startDate}</span>
                </span>
                <span className={[styles.endDate, styles.line].join(' ')}>
                    <span className={styles.title}>{t('Datum splatnosti')}</span>
                    <span className={styles.value}>{endDate}</span>
                </span>
            </div>
            { loan.currentAmount >= 5 &&
                <div className={styles.footer}>
                    <span className={[styles.currentAmount, styles.line].join(' ')}>
                        <span className={styles.title}>{t('Dlužná jistina')}</span>
                        <span className={styles.value}>{loan.currentAmount} {loan.currency}</span>
                    </span>
                </div>
            }
        </div>

        // <li className={[styles.loanLoan, stateStyle, styles.card].join(" ")}>
        //     <span className={styles.number}>
        //         <span className={styles.title}>{t('Číslo půjčky')}</span>
        //         <span className={styles.value}>{loan.number}</span>
        //     </span>
        //     <span className={styles.startAmount}>
        //         <span className={styles.title}>{t('Počáteční jistina')}</span>
        //         <span className={styles.value}>{loan.startAmount} {loan.currency}</span>
        //     </span>
        //     <span className={styles.startDate}>
        //         <span className={styles.title}>{t('Datum vypůjčení')}</span>
        //         <span className={styles.value}>{startDate}</span>
        //     </span>
        //     <span className={styles.endDate}>
        //         <span className={styles.title}>{t('Datum splatnosti')}</span>
        //         <span className={styles.value}>{endDate}</span>
        //     </span>
        //     <span className={styles.currentAmount}>
        //         <span className={styles.title}>{t('Dlužná jistina')}</span>
        //         <span className={styles.value}>{loan.currentAmount} {loan.currency}</span>
        //     </span>
        // </li> 
    );
};

export default LoanLoan;