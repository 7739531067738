import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Faze.module.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import FazeStateCard from './FazeStateCard';


const FazeState = ({state}) => {

    const { t } = useTranslation();

    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 769);

    useEffect(() => {
        const handleResize = () => setIsDesktop(window.innerWidth >= 769);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (

        <div className={styles.fazeState}>
            { isDesktop ?
                <Swiper 
                    centeredSlides={false}
                    spaceBetween={80}
                    slidesPerView={1}
                    className={styles.fazeStateSwiper}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    // onSlideChange={() => console.log('slide change')}
                >{
                    state.cardList.map((card, index, cardList) => (
                        <SwiperSlide key={card.id}>
                            <FazeStateCard card={card} position={index + 1} count={cardList.length} />
                        </SwiperSlide>
                    ))
                }</Swiper> :
                <Swiper 
                    centeredSlides={true}
                    spaceBetween={10}
                    slidesPerView={1.05}
                    className={styles.fazeStateSwiper}
                >{
                    state.cardList.map((card, index, cardList) => (
                        <SwiperSlide key={card.id}>
                            <FazeStateCard card={card} position={index + 1} count={cardList.length} />
                        </SwiperSlide>
                    ))
                }</Swiper>
            }
        </div>

        
    );
};

export default FazeState;