import axios from "axios";
import i18next from "i18next";


export const getReferenceList = (apiKey) => axios({
        url: 'https://api.es-europe.eu/reference',
        method: 'GET',
        headers: {
            token: apiKey
        }
    })
    .then(response => response.data.data)
    .then(references => references.referenceList.map(
        (reference) => ({
            id:         Number(reference.id),
            firstName:  String(reference.firstName),
            lastName:   String(reference.lastName),
            phone:      String(reference.phone),
            mail:       String(reference.mail),
            state:      Number(reference.state)
        }))
    )
    .catch(error => Promise.reject(i18next.t('Nepodařilo se načíst seznam referencí')));