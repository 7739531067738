import { 
    combineReducers, 
    configureStore, 
    getDefaultMiddleware 
} from "@reduxjs/toolkit";
import userReducer from "../features/user/userSlice";
import commonReducer from "../features/common/commonSlice";
import harmonogramReducer from "../features/harmonogram/harmonogramSlice";
import communicationReducer from "../features/communication/communicationSlice";
import helplineReducer from "../features/helpline/helplineSlice";
import fileReducer from "../features/file/fileSlice";
import loanReducer from "../features/loan/loanSlice";
import fazeReducer from "../features/faze/fazeSlice";
import referenceReducer from "../features/reference/referenceSlice";
import serviceReducer from "../features/service/serviceSlice";
import indexReducer from "../features/index/indexSlice";

const combinedReducer = combineReducers({
    user: userReducer,
    common: commonReducer,
    harmonogram: harmonogramReducer,
    communication: communicationReducer,
    helpline: helplineReducer,
    file: fileReducer,
    loan: loanReducer,
    faze: fazeReducer,
    reference: referenceReducer,
    service: serviceReducer,
    index: indexReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'user/resetUser') { // check for action type 
        state = undefined;
    }

    return combinedReducer(state, action);
};

export default configureStore({
    reducer: rootReducer,
    // middleware: [...getDefaultMiddleware()]
});