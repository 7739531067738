import React from 'react';
import styles from './Common.module.css';


const LocalActivityIndicator = ({size}) => {

    let sizeStyle = styles.localActivityIndicatorSmall;
    if (size === 'medium')
        sizeStyle = styles.localActivityIndicatorMedium;
    else if (size === 'big')
        sizeStyle = styles.localActivityIndicatorBig;
    return (
        <div className={[styles.localActivityIndicator,sizeStyle].join(' ')}></div>
    );
};

export default LocalActivityIndicator;