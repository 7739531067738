import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styles from './File.module.css';
import {
    fileSelector
} from './fileSlice';
import LocalActivityIndicator from '../common/LocalActivityIndicator';
import FileFileList from './FileFileList';
import { loadFileList } from './thunks/loadFileList';
import { fileListStateEnum } from './enum/fileListStateEnum';


const File = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const file = useSelector(fileSelector);

    useEffect(
        () => {
            if (file.fileListState === fileListStateEnum.EMPTY)
                dispatch(loadFileList());
        }, 
        [dispatch, file]
    );
    

    return (
        <div className={styles.file}>

            <div className={[styles.motivation, styles.card].join(' ')}>
                <div className={styles.content}>
                    <h3>{t("Soubory ke stažení")}</h3>
                    {t("FILE_MOTIVATION").split("\n").map((line, index) => <p key={index}>{line}</p>)}
                </div>
            </div>

            <div className={styles.layout}>
            { file.fileListState === fileListStateEnum.UNSUCCESSFULLY_LOADED &&
                <div className={[styles.messageBox, styles.negative].join(' ')}>
                    <p>{t('Nepodařilo se načíst data, zkuste to prosím ještě jednou.')}</p>
                    <button className={styles.button} onClick={() => dispatch(loadFileList())}>{t('Načíst znovu')}</button>
                </div>
            }
            { file.fileListState === fileListStateEnum.PENDING &&
                <div className={styles.activityIndicatorContainer}><LocalActivityIndicator size="small" /></div>
            }
            { file.fileListState === fileListStateEnum.READY &&
                    <FileFileList />
            }
            </div>

        </div>
    );
}

export default File;