import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProfile } from '../../../api/getProfile';
import { getStorageApiKey } from '../../../storage/localStorage';
import { setProfile, setApiKey, setPasswordRequestKey } from '../userSlice';


const getIAApiKey = () => {
    let re = /^\/access\/(\w+)$/;
    let match = window.location.pathname.match(re);

    if (match == null)
        return null;

    return match[1];
};

const getPasswordRequestKey = () => {
    let re = /^\/password\/(\w+)$/;
    let match = window.location.pathname.match(re);

    if (match == null)
        return null;

    return match[1];
};

export const init = createAsyncThunk(
    'user/init',

    async (payload, { dispatch }) => {

        // password request
        let passwordRequestKey = getPasswordRequestKey();
        if (passwordRequestKey != null) {
            dispatch(setPasswordRequestKey(passwordRequestKey));
            return Promise.resolve(true);
        }

        // get ia login data
        // do not write it to localstorage
        let apiKey =  getIAApiKey();
        if (apiKey != null) {
            dispatch(setApiKey(apiKey));
            return getProfile(apiKey)
                .then(profile => dispatch(setProfile(profile)));
        }

        // get localstorage data
        apiKey = getStorageApiKey();
        if (apiKey != null) {
            dispatch(setApiKey(apiKey));
            return getProfile(apiKey)
                .then(profile => dispatch(setProfile(profile)));
        }

        return Promise.resolve(true);
    }
);