import { createAsyncThunk } from '@reduxjs/toolkit';
import { postComplaint } from '../../../api/postComplaint';


export const sendComplaint = createAsyncThunk(
    'service/sendComplaint',
    async ({ severity, message }, { getState }) => {
        const state = getState();

        return postComplaint(
            state.user.key, 
            severity, 
            message
        ).then(() => true);
    }
);