import axios from "axios";
import i18next from "i18next";


export const getProfile = (apiKey) => axios({
        url: 'https://api.es-europe.eu/profile',
        method: 'GET',
        headers: {
            token: apiKey
        }
    })
    .then(response => response.data.data)
    .then(profile => ({
        firstName:          String(profile.firstName),
        lastName:           String(profile.lastName),
        number:             String(profile.number),
        phone:              String(profile.phone),
        mail:               String(profile.mail),
        street:             String(profile.street),
        city:               String(profile.city),
        zip:                String(profile.zip),
        program:            String(profile.program),
        programKind:        Number(profile.programKind),
        programDescription: String(profile.programDescription),
        programVersion:     String(profile.programVersion),
        state:              String(profile.state),
        stateDescription:   String(profile.stateDescription),
        company:            Number(profile.company)
    }))
    .catch((error) => Promise.reject(i18next.t('Nepodařilo se načíst profil uživatele')));