import React from 'react';
import { useTranslation } from 'react-i18next';
import { referenceStateEnum } from './enum/referenceStateEnum';
import styles from './Reference.module.css';


const ReferenceReference = ({reference}) => {

    const { t } = useTranslation();

    const referenceState = ({
        [referenceStateEnum.NEW]: t('nová reference'),
        [referenceStateEnum.CONTACT]: t('kontaktován manažerem'),
        [referenceStateEnum.CATALOGUE]: t('odeslán katalog'),
        [referenceStateEnum.APPOINTMENT]: t('schůzka'),
        [referenceStateEnum.SOS]: t('podpis SOS'),
        [referenceStateEnum.SOD]: t('podpis SOD'),
        [referenceStateEnum.FINISH]: t('dokončení stavby'),
        [referenceStateEnum.WITHDRAWAL]: t('odstup od smlouvy')
    })[reference.state];

    return (
        <li className={[styles.referenceReference].join(" ")}>
            <span className={styles.firstName}>
                <span className={styles.title}>{t('Jméno')}</span>
                <span className={styles.value}>{reference.firstName}</span>
            </span>
            <span className={styles.lastName}>
                <span className={styles.title}>{t('Příjmení')}</span>
                <span className={styles.value}>{reference.lastName}</span>
            </span>
            <span className={styles.phone}>
                <span className={styles.title}>{t('Telefon')}</span>
                <span className={styles.value}>{reference.phone}</span>
            </span>
            <span className={styles.mail}>
                <span className={styles.title}>{t('E-mail')}</span>
                <span className={styles.value}>{reference.mail}</span>
            </span>
            <span className={styles.state}>
                <span className={styles.title}>{t('Stav')}</span>
                <span className={styles.value}>{referenceState}</span>
            </span>
        </li>
    );
};

export default ReferenceReference;