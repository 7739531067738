import axios from "axios";
import i18next from "i18next";


export const postLogout = (apiKey) => axios({
        url: 'https://api.es-europe.eu/logout',
        method: 'POST',
        headers: {
            token: apiKey
        }
    })
    .catch(error => Promise.reject(i18next.t('Nepodařilo se odhlásit uživatele')));