import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Communication.module.css';
import {
    resetNewMessage,
    communicationSelector,
    setNewMessageMessage
} from './communicationSlice';
import LocalActivityIndicator from '../common/LocalActivityIndicator';
import { sendMessage } from './thunks/sendMessage';
import { newMessageMessageStateEnum } from './enum/newMessageMessageStateEnum';

const CommunicationNewMessageMessage = () => {

    const { t } = useTranslation();
    const communication = useSelector(communicationSelector);
    const dispatch = useDispatch();

    const validate = () => {
        if (communication.newMessageMessage.length > 5)
            return true;
        return false;
    };

    const submit = () => {
        
        // if (!validate()) {
        //     dispatch(setMessage(t("Vstupní data nejsou validní")));
        //     return false;
        // }

        dispatch(
            sendMessage({
                recipient: communication.newMessageRecipient, 
                message: communication.newMessageMessage
            })
        );

        return false;
    };

    const change = (text) => {
        dispatch(setNewMessageMessage(text));
    };

    return (
        <div className={styles.message}>
            { communication.newMessageMessageState === newMessageMessageStateEnum.UNSUCCESSFULLY_SENT &&
                <div className={[styles.messageBox, styles.negative].join(' ')}>
                    <p>{t('Nepodařilo se odeslat zprávu, zkuste to prosím ještě jednou.')}</p>
                </div>
            }
            { communication.newMessageMessageState === newMessageMessageStateEnum.SUCCESSFULLY_SENT &&
                <div className={[styles.messageBox, styles.pozitive].join(' ')}>
                    <p>{t('Data byla úspěšně vložena')}</p>
                    <button className={styles.button} onClick={() => dispatch(resetNewMessage())}>{t('Zavřít')}</button>
                </div>
            }
            { communication.newMessageMessageState !== newMessageMessageStateEnum.SUCCESSFULLY_SENT &&
                <div className={styles.messageForm}>
                    <div className={styles.recipient}>
                        <span className={styles.title}>{t('Příjemce')}</span>
                        <span className={styles.name}>{communication.recipientList.find(element => element.id === communication.newMessageRecipient).name}</span>
                    </div>
                    <textarea name="message" onChange={(event) => change(event.target.value)} placeholder={t("Sem napište zprávu")} value={communication.newMessageMessage} />
                    <div className={styles.control}>
                        { validate() && 
                            <button className={styles.submit} onClick={() => submit()}>{t("Odeslat")}</button>
                        }
                        <button className={styles.cancel} onClick={() => dispatch(resetNewMessage())}>{t("Zrušit")}</button>
                    </div>
                    { communication.newMessageMessageState === newMessageMessageStateEnum.PENDING &&
                        <div className={styles.activityIndicatorContainer}><LocalActivityIndicator size="small" /></div>
                    }
                </div>
            }
        </div>
    );
};


export default CommunicationNewMessageMessage;