import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import LocalActivityIndicator from '../common/LocalActivityIndicator';
import { emotionEnum } from './enum/emotionEnum';
import { loadPassword } from './thunks/loadPassword';
import styles from './User.module.css';
import { resetPasswordRequest, setMessage, userSelector } from './userSlice';


const PasswordRequestGenerated = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const user = useSelector(userSelector);

    const close = () => {
        if (!window.confirm(t('Jakmile opustíte tuto stránku, nebude možné heslo znovu vyvolat. Opravdu chcete opustit stránku?')))
            return false;

        dispatch(resetPasswordRequest());

        return false;
    };

    const copy = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(user.requestedPassword);
            dispatch(
                setMessage({
                    text: t('Heslo bylo zkopírováno do schránky'),
                    emotion: emotionEnum.POZITIVE
                })
            );
        } else {
            dispatch(
                setMessage({
                    text: t('Nepodařilo se zkopírovat heslo do schránky'),
                    emotion: emotionEnum.NEGATIVE
                })
            );
        }

        return false;
    };

    const generate = () => {
        dispatch(loadPassword(user.passwordRequestKey));

        return false;
    };

    
    return (
        <div className={styles.passwordRequestGenerated}>
            { user.message &&
                <div className={[styles.messageBox, user.message.emotion === emotionEnum.NEGATIVE ? styles.negative : styles.pozitive].join(' ')}>
                    <p>{user.message.text}</p>
                </div>
            }
            { !user.requestedPassword ?
                (
                    <div className={styles.welcome}>
                        <div className={styles.description}>
                            <p>{t('Stisknutím tlačítka generovat se vám vygeneruje nové heslo')}</p>
                        </div>
                        <div className={styles.generate}>
                            { user.pending ?
                                (
                                    <div className={styles.activityIndicatorContainer}><LocalActivityIndicator size="small" /></div>
                                ) :
                                (
                                    <button onClick={() => generate()}>{t('generovat')}</button>
                                )
                            }
                        </div>
                    </div>
                ) :
                (
                    <div className={styles.result}>
                        <div className={styles.password}>
                            <label>
                                <input type="text" value={user.requestedPassword} readOnly autoComplete="new-password" name="password" />
                                <button className={styles.copy} onClick={() => copy()}>{t('kopírovat')}</button>
                            </label>
                        </div>
                        <div className={styles.description}>
                            <p>{t('Zkopírujte si toto heslo tlačítkem kopírovat do schránky nebo si jej opište. Pro přihlášení do systému budete potřebovat váš variabilní symbol a toto heslo.')}</p>
                        </div>
                        <div className={styles.control}>
                            <button className={styles.button} onClick={() => close()}>{t('pokračovat')}</button>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default PasswordRequestGenerated;