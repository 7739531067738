import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Faze.module.css';
import { fazeSelector } from './fazeSlice';
import FazeState from './FazeState';
import { stateListStateEnum } from './enum/stateListStateEnum';
import { useTranslation } from 'react-i18next';
import { loadStateList } from './thunks/loadStateList';
import LocalActivityIndicator from '../common/LocalActivityIndicator';


const FazeStateList = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const faze = useSelector(fazeSelector);

    useEffect(
        () => {
            if (faze.stateListState === stateListStateEnum.EMPTY)
                dispatch(loadStateList());
        }, 
        [dispatch, faze]
    );

    return (
        <div className={styles.fazeStateList}>
            { faze.stateListState === stateListStateEnum.UNSUCCESSFULLY_LOADED &&
                <div className={[styles.messageBox, styles.negative].join(' ')}>
                    <p>{t('Nepodařilo se načíst data, zkuste to prosím ještě jednou.')}</p>
                    <button className={styles.button} onClick={() => dispatch(loadStateList())}>{t('Načíst znovu')}</button>
                </div>
            }
            { faze.stateListState === stateListStateEnum.PENDING &&
                <div className={styles.activityIndicatorContainer}><LocalActivityIndicator size="small" /></div>
            }
            { 
                faze.stateListState === stateListStateEnum.READY &&
                faze.stateList.length > 0 &&
                <div className={styles.list}>{
                    faze.stateList.slice().reverse().map((state) => (
                        <FazeState key={state.id} state={state} />
                    ))
                }</div>
            }
            { 
                faze.stateListState === stateListStateEnum.READY &&
                faze.stateList.length === 0 &&
                <p>{t('Nemáte žádné aktivní karty')}</p>
            }
        </div>
    );
};


export default FazeStateList;