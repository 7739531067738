import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFileList } from '../../../api/getFileList';


export const loadFileList = createAsyncThunk(
    'file/loadFileList',
    async (arg, { getState }) => {
        const state = getState();
        
        return getFileList(state.user.key);
    }
);

