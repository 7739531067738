import { createSlice } from '@reduxjs/toolkit';
import { fileDownloadStateEnum } from './enum/fileDownloadStateEnum';
import { fileListStateEnum } from './enum/fileListStateEnum';
import { loadFile } from './thunks/loadFile';
import { loadFileList } from './thunks/loadFileList';


export const fileSlice = createSlice({
    name: 'file',
    initialState : {
        fileDownloadState: fileDownloadStateEnum.READY,
        fileDownloadProgress: 0,
        fileDownloadId: null,
        fileListState: fileListStateEnum.EMPTY,
        fileList: null,
    },
    reducers: {
        setDownloadProgress: (state, action) => {
            state.fileDownloadProgress = action.payload;
        },
        setDownloadId: (state, action) => {
            state.fileDownloadId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadFileList.fulfilled, (state, action) => {
                state.fileList = action.payload;
                state.fileListState = fileListStateEnum.READY;
            })
            .addCase(loadFileList.pending, (state, action) => {
                state.fileListState = fileListStateEnum.PENDING;
            })
            .addCase(loadFileList.rejected, (state, action) => {
                state.fileListState = fileListStateEnum.UNSUCCESSFULLY_SENT;
            })
            .addCase(loadFile.fulfilled, (state, action) => {
                state.fileDownloadState = fileDownloadStateEnum.READY;
                state.fileDownloadId = null;
            })
            .addCase(loadFile.pending, (state, action) => {
                state.fileDownloadState = fileDownloadStateEnum.RUNNING;
                state.fileDownloadProgress = 0;
            })
            .addCase(loadFile.rejected, (state, action) => {
                state.fileDownloadState = fileDownloadStateEnum.READY;
                state.fileDownloadId = null;
            });
    }
});

export const { 
    setDownloadProgress,
    setDownloadId
    // resetMessageState,
    // setMessage
} = fileSlice.actions;

export const fileSelector = (state) => state.file;

export default fileSlice.reducer;