import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Harmonogram.module.css';
import {
    harmonogramSelector
} from './harmonogramSlice';
import LocalActivityIndicator from '../common/LocalActivityIndicator';
import HarmonogramHarmonogram from './HarmonogramHarmonogram';
import HarmonogramSummary from './HarmonogramSummary';
import HarmonogramNoticeList from './HarmonogramNoticeList';
import { loadHarmonogram } from './thunks/loadHarmonogram';
import { harmonogramStateEnum } from './enum/harmonogramStateEnum';


const Harmonogram = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const harmonogram = useSelector(harmonogramSelector);

    useEffect(
        () => {
            if (harmonogram.harmonogramState === harmonogramStateEnum.EMPTY)
                dispatch(loadHarmonogram());
        }, 
        [dispatch, harmonogram]
    );
    

    return (
        // <div className={styles.harmonogram}>
        //     { harmonogram.harmonogramState === harmonogramStateEnum.UNSUCCESSFULLY_LOADED &&
        //         <div className={[styles.messageBox, styles.negative].join(' ')}>
        //             <p>{t('Nepodařilo se načíst data, zkuste to prosím ještě jednou.')}</p>
        //             <button className={styles.button} onClick={() => dispatch(loadHarmonogram())}>{t('Načíst znovu')}</button>
        //         </div>
        //     }
        //     { harmonogram.harmonogramState === harmonogramStateEnum.PENDING &&
        //         <div className={styles.activityIndicatorContainer}><LocalActivityIndicator size="small" /></div>
        //     }
        //     { harmonogram.harmonogramState === harmonogramStateEnum.READY &&
        //         <div className={styles.layout}>
        //             <HarmonogramHarmonogram />
        //             <HarmonogramSummary />
        //         </div>
        //     }
        // </div>

        <div className={styles.harmonogram}>
            { harmonogram.harmonogramState === harmonogramStateEnum.UNSUCCESSFULLY_LOADED &&
                <div className={[styles.messageBox, styles.negative].join(' ')}>
                    <p>{t('Nepodařilo se načíst data, zkuste to prosím ještě jednou.')}</p>
                    <button className={styles.button} onClick={() => dispatch(loadHarmonogram())}>{t('Načíst znovu')}</button>
                </div>
            }
            { harmonogram.harmonogramState === harmonogramStateEnum.PENDING &&
                <div className={styles.activityIndicatorContainer}><LocalActivityIndicator size="small" /></div>
            }
            { harmonogram.harmonogramState === harmonogramStateEnum.READY &&
                <div className={styles.layout}>
                    <HarmonogramNoticeList />
                    <HarmonogramHarmonogram />
                    <HarmonogramSummary />
                </div>
            }
        </div>
    );
}

export default Harmonogram;