import React from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { programKindEnum } from '../user/enum/programKindEnum';
import { userSelector } from '../user/userSlice';
import styles from './Index.module.css';
import FazeStateCard from "../faze/FazeStateCard";
import { fazeSelector } from '../faze/fazeSlice';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const IndexFaze = () => {

    const { t } = useTranslation();

    const faze = useSelector(fazeSelector);

    // const cardImageSrc = "https://es-europe.eu/data/client/card/card_" + card.name + ".jpg"

    const lastState = [...faze.stateList].pop();
    const lastStateCardList = [...lastState.cardList].reverse();

    return (
        <>
            <Swiper 
                centeredSlides={true}
                spaceBetween={10}
                slidesPerView={1.05}
                className={styles.fazeStateSwiper}
                // onSlideChange={() => console.log('slide change')}
            >{
                lastStateCardList.map((card, index, lastStateCardList) => (
                    <SwiperSlide key={card.id}>
                        <FazeStateCard card={card} position={lastStateCardList.length - index} count={lastStateCardList.length} />
                    </SwiperSlide>
                ))
            }</Swiper>
            <div className={styles.fazeHistory}><Link className={styles.historyLink} to={'/faze'}>{t("Historie karet")}</Link></div>
        </>
    );

    // return (
    //     <>
    //         <div className={[styles.card, styles.faze].join(' ')}>
    //             <div className={styles.header}>
    //                 <span className={styles.title}>{t("Aktuálni fáze stavby")}</span>
    //                 <span className={styles.progress}>1/11</span>
    //             </div>
    //             <div className={styles.content}>
    //                 <div className={styles.text}>
    //                     <h2>Audit energetické efektivity</h2>
    //                     <p>Každý prodejce dané technologie Vás bude přesvědčovat, jak se pořízení právě jeho zařízení vyplatí. Bohužel není vždy pravda, co Vám budou tito obchodníci tvrdit.</p>
    //                     <p><img src="https://es-europe.eu/data/client/card/card_5939FD3C6E6A4353AAC7BDC90D5EABE4.jpg" /></p>
    //                     <p>Dáme Vám znalost a výpočty, abyste svá rozhodnutí mohli činit sebevědomě na základě faktů. Proto jsme v ES vytvořili novou službu energetického auditu. Spočteme Vám, jakou úsporu Vám která technologie přinese. A zdali se v době své životnosti vůbec zaplatí a tedy vyplatí.</p>
    //                     <p>Více v článku na Blogu Davida Mencla: <a href="https://davidmencl.cz/efektivita-vytapeni-maly-dum/">Efektivita vytápění - Malý dům</a></p>
    //                 </div>
    //                 <img className={styles.image} src="https://es-europe.eu/data/client/card/card_5939FD3C6E6A4353AAC7BDC90D5EABE4.jpg" alt="Ilustrace" />
    //             </div>
    //             <div className={styles.footer}>
    //                 <Link className={styles.button} to={'/faze/8'}>
    //                     <span>{t("Číst více")}</span>
    //                     <i></i>
    //                 </Link>
    //             </div>
    //         </div>
    //         <div className={styles.fazeHistory}><Link className={styles.historyLink} to={'/faze'}>{t("Historie karet")}</Link></div>
    //     </>
    // );
};

export default IndexFaze;