import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Faze.module.css';


const CardContent = ({card}) => {

    const { t } = useTranslation();
    
    return (

        <div className={styles.layout}>

            <div className={[styles.cardContent, styles.card].join(' ')}>
                <div className={styles.header}>
                    <span className={styles.title}>{t("Karta")}</span>
                </div>
                <div className={styles.content} dangerouslySetInnerHTML={{ __html: card.content }} />
            </div>
        </div>
    );
}

export default CardContent;