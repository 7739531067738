import React from 'react';
import styles from './User.module.css';
import PasswordRequestGenerated from './PasswordRequestGenerated';

const PasswordRequestPage = () => {

    return (
        <div className={styles.passwordRequestPage}>
            <PasswordRequestGenerated />
        </div>
    );
};

export default PasswordRequestPage;