import { createSlice } from '@reduxjs/toolkit';
import { init } from './thunks/init';
import { login } from './thunks/login';
import { logout } from './thunks/logout';
import { loadPassword } from './thunks/loadPassword';
import { emotionEnum } from './enum/emotionEnum';


export const userSlice = createSlice({
    name: 'user',
    initialState : {
        passwordRequestKey: null,
        requestedPassword: null,
        key: null,
        user: null,
        pending: false,
        message: null
    },
    reducers: {
        setPasswordRequestKey: (state, action) => {
            state.passwordRequestKey = action.payload;
        },
        setRequestedPassword: (state, action) => {
            state.requestedPassword = action.payload;
        },
        resetPasswordRequest: (state, action) => {
            state.passwordRequestKey = null;
            state.requestedPassword = null;
            state.message = null;
        },
        setApiKey: (state, action) => {
            state.key = action.payload;
        },
        setProfile: (state, action) => {
            state.user = {
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                number: action.payload.number,
                phone: action.payload.phone,
                mail: action.payload.mail,
                street: action.payload.street,
                city: action.payload.city,
                zip: action.payload.zip,
                program: action.payload.program,
                programKind: action.payload.programKind,
                programDescription: action.payload.programDescription,
                programVersion: action.payload.programVersion,
                state: action.payload.state,
                stateDescription: action.payload.stateDescription,
                company: action.payload.company
            };
        },
        resetUser: (state) => {
            // used for state reset
        },
        setMessage: (state, action) => {
            state.message = {
                text: action.payload.text,
                emotion: action.payload.emotion
            };
        },
        resetMessage: (state) => {
            state.message = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                state.pending = false;
                state.message = null;
            })
            .addCase(login.pending, (state, action) => {
                state.pending = true;
                state.message = null;
            })
            .addCase(login.rejected, (state, action) => {
                state.message = {
                    text: action.error.message,
                    emotion: emotionEnum.NEGATIVE
                };
                state.pending = false;
            })
            .addCase(init.fulfilled, (state, action) => {
                state.pending = false;
                state.message = null;
            })
            .addCase(init.pending, (state, action) => {
                state.pending = true;
                state.message = null;
            })
            .addCase(init.rejected, (state, action) => {
                state.message = {
                    text: action.error.message,
                    emotion: emotionEnum.NEGATIVE
                }; 
                state.pending = false;
            })
            .addCase(logout.fulfilled, (state, action) => {
                state.pending = false;
                state.message = null;
            })
            .addCase(logout.pending, (state, action) => {
                state.pending = true;
                state.message = null;
            })
            .addCase(logout.rejected, (state, action) => {
                state.message = null;
                state.pending = false;
            })
            .addCase(loadPassword.fulfilled, (state, action) => {
                state.pending = false;
                state.message = null;
            })
            .addCase(loadPassword.pending, (state, action) => {
                state.pending = true;
                state.message = null;
            })
            .addCase(loadPassword.rejected, (state, action) => {
                state.message = {
                    text: action.error.message,
                    emotion: emotionEnum.NEGATIVE
                }; 
                state.pending = false;
            })
    }
});

export const { 
    setApiKey, 
    setProfile, 
    resetUser, 
    resetMessage, 
    setMessage, 
    setPasswordRequestKey, 
    setRequestedPassword,
    resetPasswordRequest
} = userSlice.actions;

export const userSelector = (state) => state.user;

export default userSlice.reducer;