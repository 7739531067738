import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Service.module.css';
import {
    resetComplaint,
    serviceSelector,
    setComplaintSeverity,
    setComplaintMessage
} from './serviceSlice';
import LocalActivityIndicator from '../common/LocalActivityIndicator';
import { sendComplaint } from './thunks/sendComplaint';
import { complaintStateEnum } from './enum/complaintStateEnum';
import { complaintSeverityEnum } from './enum/complaintSeverityEnum';

const ServiceNewComplaint = () => {

    const { t } = useTranslation();
    const service = useSelector(serviceSelector);
    const dispatch = useDispatch();

    const validate = () => {
        // if (service.newComplaint.length > 5)
        //     return true;
        // if (service.newComplaint.length > 5)
        //     return true;
        // return false;

        return true;
    };

    const submit = (event) => {
        
        event.preventDefault();
        
        // if (!validate()) {
        //     dispatch(setMessage(t("Vstupní data nejsou validní")));
        //     return false;
        // }

        dispatch(
            sendComplaint({
                severity: service.complaintSeverity, 
                message: service.complaintMessage
            })
        );

        return false;
    };

    const changeMessage = (text) => {
        dispatch(setComplaintMessage(text));
    };

    const changeSeverity = (severity) => {
        dispatch(setComplaintSeverity(severity));
    };

    return (
        <div className={styles.serviceNewComplaint}>
            { service.complaintState === complaintStateEnum.UNSUCCESSFULLY_SENT &&
                <div className={[styles.messageBox, styles.negative].join(' ')}>
                    <p>{t('Nepodařilo se odeslat referenci, zkuste to prosím ještě jednou.')}</p>
                </div>
            }
            { service.complaintState === complaintStateEnum.SUCCESSFULLY_SENT &&
                <div className={[styles.messageBox, styles.pozitive].join(' ')}>
                    <p>{t('Data byla úspěšně vložena')}</p>
                    <button className={styles.button} onClick={() => dispatch(resetComplaint())}>{t('Zavřít')}</button>
                </div>
            }
            { service.complaintState !== complaintStateEnum.SUCCESSFULLY_SENT &&
                <div className={styles.severity}>
                    <ul>
                        <li className={[styles.severityService, service.complaintSeverity === complaintSeverityEnum.SERVICE ? styles.current : null].join(" ")}
                            onClick={() => changeSeverity(complaintSeverityEnum.SERVICE)}>
                                <span className={styles.severity}>{t("servis")}</span>
                                <span className={styles.description}>{t("SERVICE_DESCRIPTION")}</span>
                        </li>
                        <li className={[styles.severityDefect, service.complaintSeverity === complaintSeverityEnum.DEFECT ? styles.current : null].join(" ")}
                            onClick={() => changeSeverity(complaintSeverityEnum.DEFECT)}>
                                <span className={styles.severity}>{t("závada")}</span>
                                <span className={styles.description}>{t("DEFECT_DESCRIPTION")}</span>
                        </li>
                        <li className={[styles.severityEmergency, service.complaintSeverity === complaintSeverityEnum.EMERGENCY ? styles.current : null].join(" ")}
                            onClick={() => changeSeverity(complaintSeverityEnum.EMERGENCY)}>
                                <span className={styles.severity}>{t("havárie")}</span>
                                <span className={styles.description}>{t("EMERGENCY_DESCRIPTION")}</span>
                        </li>
                    </ul>
                </div>
            }
            { service.complaintState !== complaintStateEnum.SUCCESSFULLY_SENT &&
                <div className={styles.message}>
                    <form onSubmit={(event) => submit(event)}>
                        <textarea name="message" onChange={(event) => changeMessage(event.target.value)} placeholder={t("Sem napište stručně vaší zprávu")} value={service.complaitMessage} autoFocus required minLength="5" />
                        <div className={styles.control}>
                            { validate() && 
                                <button className={styles.submit}>{t("Odeslat")}</button>
                            }
                            <button className={styles.cancel} onClick={() => dispatch(resetComplaint())}>{t("Zrušit")}</button>
                        </div>
                    </form>
                    { service.complaintState === complaintStateEnum.PENDING &&
                        <div className={styles.activityIndicatorContainer}><LocalActivityIndicator size="small" /></div>
                    }
                </div>
            }
        </div>
    );
};


export default ServiceNewComplaint;