import React from 'react';
import styles from './Common.module.css';

const LocalProgressBar = ({size = 'small', progress = 0}) => {

    const sizeStyle = ({
        'small': styles.small,
        'medium': styles.medium,
        'big': styles.big
    })[size];

    return (
        <div className={[styles.localProgressBar, sizeStyle].join(' ')}>
            <div style={{width: progress + '%'}}></div>
        </div>
    );
}

export default LocalProgressBar;