import React from 'react';
import Page from '../common/Page';
import Index from './Index';


const IndexPage = () => {

    return (
        <Page>
            <Index />
        </Page>
    );
};

export default IndexPage;