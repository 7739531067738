import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Loan.module.css';
import {
    loanSelector
} from './loanSlice';
import LoanLoan from './LoanLoan';
import { loanListStateEnum } from './enum/loanListStateEnum';
import { useTranslation } from 'react-i18next';
import { loadLoanList } from './thunks/loadLoanList';
import LocalActivityIndicator from '../common/LocalActivityIndicator';


const LoanLoanList = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const loan = useSelector(loanSelector);

    useEffect(
        () => {
            if (loan.loanListState === loanListStateEnum.EMPTY)
                dispatch(loadLoanList());
        }, 
        [dispatch, loan]
    );

    return (
        <div className={styles.loanLoanList}>
            { loan.loanListState === loanListStateEnum.UNSUCCESSFULLY_LOADED &&
                <div className={[styles.messageBox, styles.negative].join(' ')}>
                    <p>{t('Nepodařilo se načíst data, zkuste to prosím ještě jednou.')}</p>
                    <button className={styles.button} onClick={() => dispatch(loadLoanList())}>{t('Načíst znovu')}</button>
                </div>
            }
            { loan.loanListState === loanListStateEnum.PENDING &&
                <div className={styles.activityIndicatorContainer}><LocalActivityIndicator size="small" /></div>
            }
            { 
                loan.loanListState === loanListStateEnum.READY &&
                loan.loanList.length > 0 &&
                loan.loanList.map((item) => (
                    <LoanLoan key={item.id} loan={item} />
                ))
            }
            { 
                loan.loanListState === loanListStateEnum.READY &&
                loan.loanList.length === 0 &&
                <p>{t('Nemáte žádné aktivní půjčky')}</p>
            }
        </div>
    );
};


export default LoanLoanList;