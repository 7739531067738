import axios from "axios";
import i18next from "i18next";


export const postComplaint = (apiKey, severity, message) => axios({
        url: 'https://api.es-europe.eu/complaint',
        method: 'POST',
        headers: {
            token: apiKey
        },
        data: {
            severity: severity,
            message: message
        }
    })
    .catch(error => Promise.reject(i18next.t('Nepodařilo se odeslat požadavek')));