import { createSlice } from '@reduxjs/toolkit';
import { indexStateEnum } from './enum/indexStateEnum';
import { loadAll } from './thunks/loadAll';


export const indexSlice = createSlice({
    name: 'index',
    initialState : {
        indexState: indexStateEnum.EMPTY
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadAll.fulfilled, (state, action) => {
                state.indexState = indexStateEnum.READY;
            })
            .addCase(loadAll.pending, (state, action) => {
                state.indexState = indexStateEnum.PENDING;
            })
            .addCase(loadAll.rejected, (state, action) => {
                state.indexState = indexStateEnum.UNSUCCESSFULLY_LOADED;
            });
    }
});

// export const { 
    
// } = loanSlice.actions;

export const indexSelector = (state) => state.index;

export default indexSlice.reducer;