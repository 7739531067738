import { createSlice } from '@reduxjs/toolkit';


export const commonSlice = createSlice({
    name: 'common',
    initialState : {
        pending: false,
        message: null,
        menu: false,
        scroll: false
    },
    reducers: {
        showActivity: (state) => {
            state.pending = true;
        },
        hideActivity: (state) => {
            state.pending = false;
        },
        showMessage: (state, action) => {
            state.message = action.payload;
        },
        hideMessage: (state, action) => {
            state.message = null;
        },
        showMenu: (state) => {
            state.menu = true;
        },
        hideMenu: (state) => {
            state.menu = false;
        },
        showScroll: (state) => {
            state.scroll = true;
        },
        hideScroll: (state) => {
            state.scroll = false;
        }
    }
});

export const { 
    showActivity, 
    hideActivity, 
    showMessage, 
    hideMessage, 
    showMenu, 
    hideMenu,
    showScroll,
    hideScroll 
} = commonSlice.actions;

export const commonSelector = (state) => state.common;

export default commonSlice.reducer;