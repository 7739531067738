import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LocalDate } from '../../lib/localDate';
import { stateKindEnum } from './enum/stateKindEnum';
import { stateStageEnum } from './enum/stateStageEnum';
import styles from './Harmonogram.module.css';
import { harmonogramSelector } from './harmonogramSlice';
import HarmonogramHurdle from './HarmonogramHurdle';


const HarmonogramNoticeList = () => {

    const { t } = useTranslation();
    const harmonogram = useSelector(harmonogramSelector);

    const currentState = harmonogram.harmonogram.find(state => state.stage === stateStageEnum.CURRENT);

    const hurdleList = harmonogram.harmonogram.filter(
        state => ( 
            [stateStageEnum.CURRENT, stateStageEnum.FUTURE].includes(state.stage) &&
            state.runningEvent
        )
    ).flatMap(
        state => state.eventList.filter(
            event => (new LocalDate(event.to)).compare((new LocalDate()).today()) == 1
        )
    );

    return (
        // <div className={styles.harmonogramNoticeList}>
        //     { currentState.eventList.length > 0 &&
        //         currentState.eventList[currentState.eventList.length - 1].to === (new LocalDate()).today().getDate() &&
        //             <div className={[styles.notice,styles.hurdle].join(' ')}>
        //                 <h3>{t("Mimořádná událost")}</h3>
        //                 <p className={styles.text}>{currentState.eventList[currentState.eventList.length - 1].note}</p>
        //             </div>
        //     }
        //     { currentState.kind === stateKindEnum.CLIENT &&
        //         <div className={[styles.notice,styles.card,styles.cooperation].join(' ')}>
        //             <h3>{t("Etapa vyžaduje vaší součinnost")}</h3>
        //             <p className={styles.text}>{t("Aktuální etapa vyžaduje vaší součinnost. Případné zpoždění není přičítáno dodavateli.")}</p>
        //         </div>
        //     }
        // </div>
        <div className={styles.harmonogramNoticeList}>
            { hurdleList.length > 0 &&
                hurdleList.map((hurdle, index) => (
                    <HarmonogramHurdle key={index} hurdle={hurdle} />
                    // <div key={index} className={[styles.notice,styles.card,styles.hurdle].join(' ')}>
                    //     <div className={styles.header}>
                    //         <span className={styles.title}>{t("Mimořádná událost")}</span>
                    //     </div>
                    //     <div className={styles.content}>
                    //         <p className={styles.text}>{hurdle.note}</p>
                    //     </div>
                    // </div>
                ))

                // currentState.eventList[currentState.eventList.length - 1].to === (new LocalDate()).today().getDate() &&
                //     <div className={[styles.notice,styles.card,styles.hurdle].join(' ')}>
                //         <div className={styles.header}>
                //             <span className={styles.title}>{t("Mimořádná událost")}</span>
                //         </div>
                //         <div className={styles.content}>
                //             <p className={styles.text}>{currentState.eventList[currentState.eventList.length - 1].note}</p>
                //         </div>
                //     </div>
            }
            {/* { currentState.eventList.length > 0 &&
                currentState.eventList[currentState.eventList.length - 1].to === (new LocalDate()).today().getDate() &&
                    <div className={[styles.notice,styles.card,styles.hurdle].join(' ')}>
                        <div className={styles.header}>
                            <span className={styles.title}>{t("Mimořádná událost")}</span>
                        </div>
                        <div className={styles.content}>
                            <p className={styles.text}>{currentState.eventList[currentState.eventList.length - 1].note}</p>
                        </div>
                    </div>
            } */}
            { currentState.kind === stateKindEnum.CLIENT &&
                <div className={[styles.notice,styles.card,styles.cooperation].join(' ')}>
                    <div className={styles.header}>
                        <span className={styles.title}>{t("Etapa vyžaduje vaší součinnost")}</span>
                    </div>
                    <div className={styles.content}>
                        <p className={styles.text}>{t("Aktuální etapa vyžaduje vaší součinnost. Případné zpoždění není přičítáno dodavateli.")}</p>
                    </div>
                </div>
            }
        </div>
    );
};

export default HarmonogramNoticeList;