import React from 'react';
import { useTranslation } from 'react-i18next';
import HarmonogramNoticeList from './HarmonogramNoticeList';
import HarmonogramStateList from './HarmonogramStateList';
import styles from './Harmonogram.module.css';

const HarmonogramHarmonogram = () => {

    const { t } = useTranslation();

    return (
        // <div className={styles.harmonogramHarmonogram}>
        //     <h2>{t("Harmonogram")}</h2>
        //     <HarmonogramNoticeList />
        //     <HarmonogramStateList />
        // </div>

        <div className={[styles.harmonogramHarmonogram, styles.card].join(' ')}>
            <div className={styles.header}>
                <span className={styles.title}>{t("Harmonogram")}</span>
            </div>
            <div className={styles.content}>
                <HarmonogramStateList />
            </div>
        </div>
    );
};

export default HarmonogramHarmonogram;