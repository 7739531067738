import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Common.module.css';
import {
    commonSelector, 
    hideMessage
} from './commonSlice';


const MessageBox = () => {

    const { t } = useTranslation();
    const common = useSelector(commonSelector);
    const dispatch = useDispatch();

    return (
        <div className={styles.messageBox}>
            {common.message}
            <button className={styles.button} onClick={() => dispatch(hideMessage())}>{t('Ok')}</button>
        </div>
    );
};

export default MessageBox;