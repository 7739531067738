import React from 'react';
import State from './State';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../common/BreadCrumbs';
import Page from '../common/Page';
import { useLocation } from 'react-router-dom';

const StatePage = () => {

    const { t } = useTranslation();
    const location = useLocation();

    return (
        <Page>
            <BreadCrumbs stack={[
                {title: t("Domů"), path: "/"},
                {title: t("Harmonogram"), path: ".."},
                {title: t("Detail"), path: location.pathname}
            ]} />
            <State />
        </Page>
    );
};

export default StatePage;