import axios from "axios";
import i18next from "i18next";


export const postReference = (apiKey, firstName, lastName, phone, mail) => axios({
        url: 'https://api.es-europe.eu/reference',
        method: 'POST',
        headers: {
            token: apiKey
        },
        data: {
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            mail: mail
        }
    })
    .then(response => response.data.data)
    .then(reference => ({
        id:         Number(reference.id),
        firstName:  String(reference.firstName),
        lastName:   String(reference.lastName),
        phone:      String(reference.phone),
        mail:       String(reference.mail),
        state:      Number(reference.state)
    }))
    .catch(error => Promise.reject(i18next.t('Nepodařilo se odeslat referenci')));