import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './Harmonogram.module.css';
import {
    harmonogramSelector
} from './harmonogramSlice';
import LocalActivityIndicator from '../common/LocalActivityIndicator';
import { loadHarmonogram } from './thunks/loadHarmonogram';
import { harmonogramStateEnum } from './enum/harmonogramStateEnum';
import StateContent from './StateContent';


const State = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const harmonogram = useSelector(harmonogramSelector);
    const { id } = useParams();

    useEffect(
        () => {
            if (harmonogram.harmonogramState === harmonogramStateEnum.EMPTY)
                dispatch(loadHarmonogram());
        }, 
        [dispatch, harmonogram]
    );
    
    return (
        <div className={styles.state}>
            { harmonogram.harmonogramState === harmonogramStateEnum.UNSUCCESSFULLY_LOADED &&
                <div className={[styles.messageBox, styles.negative].join(' ')}>
                    <p>{t('Nepodařilo se načíst data, zkuste to prosím ještě jednou.')}</p>
                    <button className={styles.button} onClick={() => dispatch(loadHarmonogram())}>{t('Načíst znovu')}</button>
                </div>
            }
            { harmonogram.harmonogramState === harmonogramStateEnum.PENDING &&
                <div className={styles.activityIndicatorContainer}><LocalActivityIndicator size="small" /></div>
            }
            { harmonogram.harmonogramState === harmonogramStateEnum.READY &&
                <StateContent state={harmonogram.harmonogram.find(state => state.id === Number(id))} />
            }
        </div>
    );
}

export default State;