import { createAsyncThunk } from '@reduxjs/toolkit';
import { getStateList } from '../../../api/getStateList';


export const loadHarmonogram = createAsyncThunk(
    'harmonogram/loadHarmonogram',
    async (arg, { getState }) => {
        const state = getState();
        
        return getStateList(state.user.key);
    }
);