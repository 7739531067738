import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProfile } from '../../../api/getProfile';
import { postLogin } from '../../../api/postLogin';
import { setStorageApiKey } from '../../../storage/localStorage';
import { setProfile, setApiKey } from '../userSlice';


export const login = createAsyncThunk(
    'user/login',
    
    async ({ user, password }, { dispatch }) => {
        return postLogin(user, password)
            .then(apiKey => {
                setStorageApiKey(apiKey);
                return apiKey;
            })
            .then(apiKey => {
                dispatch(setApiKey(apiKey));
                return apiKey;
            })
            .then(apiKey => getProfile(apiKey))
            .then(profile => dispatch(setProfile(profile)));
    }
);
