import axios from "axios";
import i18next from "i18next";


export const postMessage = (apiKey, message, userId) => axios({
        url: 'https://api.es-europe.eu/message',
        method: 'POST',
        headers: {
            token: apiKey
        },
        data: {
            message: message,
            userId: userId
        }
    })
    .then(response => response.data.data)
    .then(message => ({
        id:         Number(message.id),
        date:       String(message.date),
        direction:  Number(message.direction),
        title:      String(message.title),
        text:       String(message.text),
        member: {
            id:         Number(message.member.id),
            name:       String(message.member.name),
            position:   Number(message.member.position),
            imageUrl:   String(message.member.imageUrl)
        }
    }))
    .catch(error => Promise.reject(i18next.t('Nepodařilo se odeslat zprávu')));