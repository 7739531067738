import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMessageList } from '../../../api/getMessageList';


export const loadMessageList = createAsyncThunk(
    'communication/loadMessageList',
    async (arg, { getState }) => {
        const state = getState();
        
        return getMessageList(state.user.key)
    }
);