import axios from "axios";
import i18next from "i18next";


export const getFile = (apiKey, fileId, onProgress) => axios({
        url: 'https://api.es-europe.eu/file/' + fileId,
        method: 'GET',
        responseType: 'blob', // important
        headers: {
            token: apiKey
        },
        onDownloadProgress: (progressEvent) => {
            if (!progressEvent.lengthComputable)
                return;

            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            onProgress(percentCompleted);
        }
    })
    .then(response => response.data)
    .catch(error => Promise.reject(i18next.t('Nepodařilo se načíst obsah souboru')));