import React from 'react';
import { useSelector } from 'react-redux';
import styles from './Communication.module.css';
import {
    communicationSelector
} from './communicationSlice';
import CommunicationNewMessageAction from './CommunicationNewMessageAction';
import CommunicationNewMessageRecipient from './CommunicationNewMessageRecipient';
import CommunicationNewMessageMessage from './CommunicationNewMessageMessage';
import { newMessageStateEnum } from './enum/newMessageStateEnum';


const CommunicationNewMessage = () => {

    const communication = useSelector(communicationSelector);

    return (
        <div className={styles.communicationNewMessage}>

            { communication.newMessageState === newMessageStateEnum.ACTION && 
                <CommunicationNewMessageAction />
            }
            
            { communication.newMessageState === newMessageStateEnum.RECIPIENT && 
                <CommunicationNewMessageRecipient />
            }

            { communication.newMessageState === newMessageStateEnum.MESSAGE &&
                <CommunicationNewMessageMessage />
            }

        </div>
    );
};


export default CommunicationNewMessage;