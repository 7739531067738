import React from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './Index.module.css';
import { harmonogramSelector } from "../harmonogram/harmonogramSlice";
import { stateStageEnum } from "../harmonogram/enum/stateStageEnum";

const IndexThermometer = () => {

    const { t } = useTranslation();
    const harmonogram = useSelector(harmonogramSelector);

    const currentState = 
        harmonogram
            .harmonogram
            .find(state => state.stage === stateStageEnum.CURRENT);

    const progress = 
        harmonogram
            .harmonogram
            .filter(state => state.stage === stateStageEnum.HISTORY)
            .reduce((sum, state) => sum + state.expectedLength, 0) + 
        (currentState !== null ? Math.min(currentState.expectedLength, currentState.realLength) : 0);
    const total = harmonogram
        .harmonogram
        .reduce((sum, state) => sum + state.expectedLength, 0);
    
    const progressWeeks = Math.ceil(progress / 7.0);
    const restWeeks = Math.ceil((total - progress) / 7.0);
    const restDays = total - progress;
    const progressPercent = Math.floor(100 * progress / total);

    return (
        <div className={[styles.card, styles.thermometer].join(' ')}>
            <span className={styles.current}>{progressWeeks}. {t("týden")}</span>
            <span className={styles.rest}>K dokončení zbýva {restWeeks} týdnu ({restDays} dní)</span>
            <div className={styles.scale}>
                <div className={styles.progress} style={{width:progressPercent + "%"}}>{progressPercent}</div>
            </div>
        </div>
    );
};

export default IndexThermometer;