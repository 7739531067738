import { createSlice } from '@reduxjs/toolkit';
import { stateListStateEnum } from './enum/stateListStateEnum';
import { loadStateList } from './thunks/loadStateList';


export const fazeSlice = createSlice({
    name: 'faze',
    initialState : {
        stateListState: stateListStateEnum.EMPTY,
        stateList: null
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadStateList.fulfilled, (state, action) => {
                state.stateList = action.payload;
                state.stateListState = stateListStateEnum.READY;
            })
            .addCase(loadStateList.pending, (state, action) => {
                state.stateListState = stateListStateEnum.PENDING;
            })
            .addCase(loadStateList.rejected, (state, action) => {
                state.stateListState = stateListStateEnum.UNSUCCESSFULLY_LOADED;
            });
    }
});

// export const { 
    
// } = loanSlice.actions;

export const fazeSelector = (state) => state.faze;

export default fazeSlice.reducer;